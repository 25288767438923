import React from 'react';
import StateBook from '../Components/Contract/StateBook';

const StatePage = () => {
    return (
        <>
            <div>
                <StateBook />
            </div>

        </>
    );
};

export default StatePage;