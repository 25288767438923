import React, { ChangeEvent, useState } from 'react';
import { getAbi, getOwnerToken, getWeb3, readBook } from '../Services/NftServices';
import { getNftOwnerToken } from '../Services/bookNftService';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';

interface Data {
    tokenId: number;
    ownerToken: string;
}

const ReaderPage: React.FC = () => {
    const [formData, setFormaData] = useState<Data>({
        tokenId: 0,
        ownerToken: ''
    })
    const [blobUrl, setBlobUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleInputElement = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormaData({ ...formData, [name]: value });
    }

    const handleReadBook = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const web3 = await getWeb3();
        const abi = await getAbi();
        const provider: any = await detectEthereumProvider();
        if (provider) {
            const web3Instance = new Web3(provider);
            await provider.request({ method: 'eth_requestAccounts' });
            const accounts = await web3Instance.eth.getAccounts();
            const account = accounts[0];

            try {
                const books = await getNftOwnerToken(formData.ownerToken);
                console.log('address contract ', books[0])
                const owner: string = await getOwnerToken(formData.tokenId, books[0].contractAddressNftDto);
                if (owner.toLocaleLowerCase() === account.toLocaleLowerCase()) {
                    const blob = await readBook(formData.tokenId, books[0].contractAddressNftDto);
                    const url = URL.createObjectURL(blob);
                    console.log('url', url);
                    setBlobUrl(url);
                } else {
                    setError('Please provide both Token ID and Contract Address');
                    // setLoading(false);
                }
            } catch (error: any) {
                console.error('Error fetching book:', error.message);
                setError('Failed to load the book.');
                // setLoading(false);
            }
        }
    };

    return (
        <>
            <div>
                {!blobUrl && (
                    <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-52 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h3 className="text-center font-semibold text-3xl mt-6"> Reader Connexion </h3>

                        <div className="justify-center mt-2">
                            <form className="max-w-md mb-6 mx-auto" onSubmit={handleReadBook}>
                                <div className="relative z-0 w-full mt-7 mb-5 group">
                                    <input type="number" name='tokenId' value={formData.tokenId} onChange={handleInputElement}
                                        id="token" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                                    <label htmlFor="token" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Token Id :</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name='ownerToken' value={formData.ownerToken} onChange={handleInputElement}
                                        id="address" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                                    <label htmlFor="address" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Owner Address Token Id  :</label>
                                </div>
                                <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                                    <button type="submit" className="font-semibold  text-white"> Read </button>
                                </div>
                            </form>

                        </div>
                    </div>)}
            </div>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            <div className='mt-36'>
                {blobUrl && (
                    <iframe title={`Document Viewer - ${new Date().toISOString()}`}
                        //src={blobUrl} width="100%" height="1000px"*
                        src={`${blobUrl}#toolbar=0&navpanes=0&scrollbar=0` } 
                        width="100%" height="1000px"
                        //  sandbox="allow-scripts allow-same-origin"                       
                        onLoad={() => URL.revokeObjectURL(blobUrl)} // Nettoyer l'URL temporaire lorsque le document est chargé
                        onContextMenu={(e) => e.preventDefault()}
                        onKeyDown={(e) => e.preventDefault()} // Intercepter les événements du clavier
                      
                        onKeyUp={(e) => e.preventDefault()}
                   
                        ></iframe>
                )}
            </div> 
                           
        </>
    )
};

export default ReaderPage;


