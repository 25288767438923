import React from 'react';

const ErrorMessag = () => {
    return (
        <>            
     
  
        </>
    );
};

export default ErrorMessag;