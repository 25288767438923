import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookInterface } from '../Interface/Book';


const Detail: React.FC = () => {
    const { idBookDto } = useParams<{idBookDto: string }>();
    const [book, setBook] = useState<BookInterface | null>(null);  
    const url = process.env.REACT_APP_SPRING_API_URL

    useEffect(() => {
        const getBook = async () => {
            try {
                const response = await axios.get(`${url}Book/${idBookDto}`);
                setBook(response.data);                
            } catch (error) {
                console.error('Error fetching book:', error);
            }
        };

        getBook();
    }, [idBookDto]);

    if (!book) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">{book.titleDto}</h1>
            <p className="text-gray-700 mb-4">{book.manuscriptDto}</p>
            <p className="text-gray-700 mb-4">{book.promotionalMessageDto}</p>            
            <p className="text-gray-700 mb-4">Author Email: {book.authorEmailDto}</p>
        </div>
    );
};

export default Detail;