import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';

const componentName = () => {
    return (
        <>            
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <Header/>
            </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <Navigation/>
            </aside>

            <footer>
                <div className="px-4 py-6 md:flex md:items-center md:justify-between">
                    <span className="text-sm text-white dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">Rhcoinx</a>. All Rights Reserved.</span>                    
                </div>
            </footer>
      </>
    );
};

export default componentName;