import React, { ChangeEvent, useState, useEffect } from 'react';
import AccountForm from './AccountForm';
import axios from 'axios';


interface FormData {
    firstNamePersonDto: string,
    lastNamePersonDto: string,
    nationalityPersonDto: string,
    phonePersonDto: string,
    profile: string[],
    status: string,
    homeStatus: string[],
    remunerations: string[],
    readerRemu: string,
    disagnRemu: string,
    printerRemu: string,
    genres: string[],
    companyName: string,
    companyLocal: string,
    companyPresent: string,
    biography: string,
    yourSelf: string,
    motivation: string
}

interface PreparData {
    [key: string]: any;
    firstNamePersonDto: string;
    lastNamePersonDto: string;
    nationalityPersonDto: string;
    phonePersonDto: string;
    profile: string[];
}

const FormInscription: React.FC = () => {

    const [formData, setFormData] = useState<FormData>({
        firstNamePersonDto: '',
        lastNamePersonDto: '',
        nationalityPersonDto: '',
        phonePersonDto: '',
        profile: [],
        status: '',
        homeStatus: [],
        remunerations: [],
        readerRemu: '',
        disagnRemu: '',
        printerRemu: '',
        genres: [],
        companyName: '',
        companyLocal: '',
        companyPresent: '',
        biography: '',
        yourSelf: '',
        motivation: ''
    });

    const inialState = {
        firstNamePersonDto: '',
        lastNamePersonDto: '',
        nationalityPersonDto: '',
        phonePersonDto: '',
        profile: [],
        status: '',
        homeStatus: [],
        remunerations: [],
        readerRemu: '',
        disagnRemu: '',
        printerRemu: '',
        genres: [],
        companyName: '',
        companyLocal: '',
        companyPresent: '',
        biography: '',
        yourSelf: '',
        motivation: ''
    }

    const prepareDataFormSubmission = (data: FormData) => {

        const preparedData: PreparData = {
            firstNamePersonDto: data.firstNamePersonDto,
            lastNamePersonDto: data.lastNamePersonDto,
            nationalityPersonDto: data.nationalityPersonDto,
            phonePersonDto: data.phonePersonDto,
            profile: data.profile
        }

        if (data.status === 'Printer') {
            preparedData.remuneration = data.printerRemu;
            preparedData.genre = data.genres;
            preparedData.yourselfPresent = data.yourSelf;
            preparedData.motivation = data.motivation
        }
        else if (data.status === 'Disagner') {
            preparedData.remuneration = data.disagnRemu;
            preparedData.genre = data.genres;
            preparedData.yourselfPresent = data.yourSelf;
            preparedData.motivation = data.motivation
        }
        else if (data.status === 'CorrectorReader') {
            preparedData.remuneration = data.readerRemu;
            preparedData.genre = data.genres;
            preparedData.yourselfPresent = data.yourSelf;
            preparedData.motivation = data.motivation
        }
        else if (data.status === 'Author') {
            preparedData.biography = data.biography;
            preparedData.genre = data.genres;
        }
        else if (data.status === 'EditionHome') {
            preparedData.profile = data.homeStatus;
            preparedData.genresDto = data.genres;
            preparedData.companyLocalityDto = data.companyLocal;
            preparedData.companyNameDto = data.companyName;
            preparedData.motivationDto = data.motivation;
            preparedData.companyPresentationDto = data.companyPresent;

            roles.map((role: string) => {
                if (role === "Printer" && data.homeStatus.includes(role))
                    data.remunerations.push(data.printerRemu);
                else if (role === "Disagner" && data.homeStatus.includes(role))
                    data.remunerations.push(data.disagnRemu);
                else if (role === "CorrectorReader" && data.homeStatus.includes(role))
                    data.remunerations.push(data.readerRemu)
            });
            preparedData.remunerationHonesDto = data.remunerations;

        }

        return preparedData;
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData, [name]: value
        });

    }


    const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData, [name]: value
        });

    }

    const roles: string[] = ["CorrectorReader", "Disagner", "Printer"];

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleHomeStatusCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFormData(prevFormData => {
            const newStatus = prevFormData.homeStatus.includes(value) ?
                prevFormData.homeStatus.filter(status => status !== value) :
                [...prevFormData.homeStatus, value];
            return { ...prevFormData, homeStatus: newStatus }

        })
    }

    const handleGenreCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFormData(prevFormData => {
            const newGenres = prevFormData.genres.includes(value) ?
                prevFormData.genres.filter(gen => gen !== value) :
                [...prevFormData.genres, value];
            return { ...prevFormData, genres: newGenres }
        });
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setFormData(prevFormData => ({ ...prevFormData, profile: [...prevFormData.profile, prevFormData.status] }));
    }

    function resetForm() {
        setFormData(inialState);
    }

    const [idPersonDto, setIdPersonDto] = useState('');
    
    useEffect(() => {

    }, [idPersonDto]); 

    const url = process.env.REACT_APP_SPRING_API_URL; 
   

    useEffect(() => {
        if (formData.profile.length > 0 && formData.status === "Person") {
            const dataToSubmit = prepareDataFormSubmission(formData);
            axios.post(`${url}AddPerson`, dataToSubmit)
                .then(response => {
                    console.log(response.data);
                    const id = response.data.idPersonDto;
                    setIdPersonDto(id);
                //    resetForm();
                })
                .catch(error => {
                    console.log(error.message);
                });
        }
        else if (formData.profile.length > 0 && formData.status === "Author") {
            const dataToSubmit = prepareDataFormSubmission(formData);
            axios.post(`${url}AddAuthor`, dataToSubmit)
                .then(response => {
                    console.log(response.data);
                    const id = response.data.idPersonDto;
                    setIdPersonDto(id);
                  //  resetForm();
                })
                .catch(error => console.log(error));
        }
        else if (formData.profile.length > 0 && (formData.status === "Printer" || formData.status === "Disagner" || formData.status === "CorrectorReader")) {
            const dataToSubmit = prepareDataFormSubmission(formData);
            axios.post(`${url}AddActor`, dataToSubmit)
                .then(response => {
                    console.log(response.data);
                    const id = response.data.idPersonDto;
                    setIdPersonDto(id);
                    resetForm()
                })
                .catch(error => console.log(error));
        }
        else if (formData.profile.length > 0 && formData.status === "EditionHome") {
            const dataToSubmit = prepareDataFormSubmission(formData);
            axios.post(`${url}AddEditionHome`, dataToSubmit)
                .then(response => {
                    console.log(response.data);
                    const id = response.data.idPersonDto;
                    setIdPersonDto(id);
                    resetForm()
                })
                .catch(error => console.log(error));
        }

    }, [formData]);


    return (

        <>
            <h1 className="text-xl font-bold text-center mt-20 leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white">
                Welcome to the registration form page
            </h1>
            <div >
                <p className='mt-10 text-xl text-center'> By filling in the fields below, you will firstly become a major player in this project designed in the world of blockchain,
                    and secondly you will enable us to have some information about you so that you can take part in the various projects
                    and activities that will be presented to the community.
                </p>
            </div>

            <div className="flex flex-col items-center w-full mt-5  bg-white border border-gray-200 rounded-lg  shadow md:flex-row md:max-w-xll hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 ">
                <div className=" p-6 space-y-3 space-x-2 w-8/12 mb-4 mt-6 ml-10 rounded-2xl group bg-gradient-to-br from-purple-500 to-pink-500 md:space-y-6 sm:p-8">
                    <h4 className="text-xl leading-tight tracking-tight text-white font-semibold md:text-2xl  md:text-center dark:text-white">
                        Profile registration form
                    </h4>
                    <form className=" " onSubmit={handleSubmit}>
                        <div className="grid gap-6 mb-6  md:grid-cols-2">
                            <div >
                                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-white dark:text-white">First name</label>
                                <input type="text" id="first_name" name="firstNamePersonDto" value={formData.firstNamePersonDto} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
                            </div>
                            <div >
                                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-white dark:text-white">Last name</label>
                                <input type="text" id="last_name" name="lastNamePersonDto" value={formData.lastNamePersonDto} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
                            </div>

                            <div >
                                <label htmlFor="phone" className="block  mb-2 text-sm font-medium text-white dark:text-white">Phone number</label>
                                <input type="tel" id="phone" name="phonePersonDto" value={formData.phonePersonDto} onChange={handleInputChange} className="bg-gray-50 border  px-15 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="692345678" required />
                            </div>

                            <div >
                                <label htmlFor="nationality" className="block mb-2 text-sm font-medium text-white dark:text-white">Nationality</label>
                                <input type="text" id="nationality" name="nationalityPersonDto" value={formData.nationalityPersonDto} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=" Cameroon " required />
                            </div>
                        </div>

                        <div className='mb-2'>
                            <span className="text-white">What role do you want to play ?</span>
                        </div>
                        <div className="flex mb-4 justify-between ">
                            <div >
                                <div className="flex items-center mb-3 me-4">
                                    <input id='author' type="radio" name="status" value="Author" checked={formData.status === "Author"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='author' className="ms-2 text-sm font-medium text-white dark:text-gray-300">Author </label>
                                </div>
                                <div className="flex items-center  me-4">
                                    <input id='user' type="radio" name="status" value="Person" checked={formData.status === "Person"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='user' className="ms-2 text-sm font-medium text-white dark:text-gray-300">User</label>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id='corrector' type="radio" name="status" value="CorrectorReader" checked={formData.status === "CorrectorReader"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='corrector' className="ms-2 text-sm font-medium text-white dark:text-gray-300">Corrector / Reader</label>

                                </div>

                                <div className="flex items-center  me-4">
                                    <input id='home' type="radio" name="status" value="EditionHome" checked={formData.status === "EditionHome"} onChange={handleRadioChange} className="w-4 h-4 mb-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='home' className="ms-2 mb-3 text-sm font-medium text-white dark:text-gray-300">Edition Home  </label>
                                    {formData.status === "EditionHome" && (
                                        <div className=' flex flex-col w-52 p-2 ml-2 bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700'>
                                            <ul>
                                                {roles.map(role =>
                                                    <li key={role}>
                                                        <label className='m-2 text-sm font-medium text-white dark:text-gray-300'>
                                                            <input type="checkbox" name={role} value={role} checked={formData.homeStatus.includes("" + role + "")} onChange={handleHomeStatusCheckBoxChange} />
                                                        </label>
                                                        <span>{role}</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id='disagner' type="radio" name="status" value="Disagner" checked={formData.status === "Disagner"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='disagner' className="ms-2 text-sm font-medium text-white dark:text-gray-300">Disagner</label>
                                </div>
                                <div className="flex items-center me-4">
                                    <input id='printer' type="radio" name="status" value="Printer" checked={formData.status === "Printer"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor='printer' className="ms-2 text-sm font-medium text-white dark:text-gray-300">Printer </label>
                                </div>
                            </div>

                        </div>

                        {(formData.status === "Author" || formData.status === "CorrectorReader" || formData.status === "Printer" || formData.status === "Disagner" || formData.status === "EditionHome") && (
                            <div>

                                <div className='mb-2'>
                                    <span className="text-white">In what literary genre do you specialise ?</span>
                                </div>
                                <div className="flex mb-3 justify-between">
                                    <div>
                                        <div className="flex items-center mb-3 me-4">
                                            <input id="checkNarra" type="checkbox" name="narrative" value="Narrative" checked={formData.genres.includes("Narrative")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkNarra" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Narrative</label>
                                        </div>
                                        <div className="flex items-center mb-3 me-4">
                                            <input id="checkNovel" type="checkbox" name="novel" value="Novel" checked={formData.genres.includes("Novel")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkNovel" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Novel</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center mb-3 me-4">
                                            <input id="checkStory" type="checkbox" name="story" value="Story" checked={formData.genres.includes("Story")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkStory" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Story</label>
                                        </div>
                                        <div className="flex items-center mb-3 me-4">
                                            <input id="checkScience" type="checkbox" name="science" value="Scientist" checked={formData.genres.includes("Scientist")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkScience" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Scientist</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center mb-3 me-4">
                                            <input id="checkPoetic" type="checkbox" name="poetic" value="Poetic" checked={formData.genres.includes("Poetic")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkPoetic" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Poetic</label>
                                        </div>
                                        <div className="flex items-cente mb-3  me-4">
                                            <input id="checktheat" type="checkbox" name="theatrical" value="Theatrical" checked={formData.genres.includes("Theatrical")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checktheat" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Theatrical </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-cente mb-3 me-4">
                                            <input id="checkother" type="checkbox" name="other" value="Other" checked={formData.genres.includes("Other")} onChange={handleGenreCheckBoxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="checkother" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Other </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(formData.status === "CorrectorReader" || (formData.status === "EditionHome" && formData.homeStatus.includes("CorrectorReader"))) && (
                            <div>
                                <span className='text-white ' > In what salary bracket do you work as a proofreader and editor? </span>
                                <div className="flex justify-between mb-4 mt-2">
                                    <div className="flex items-center me-4">
                                        <input id="inline-radio" type="radio" name="readerRemu" value="RemuReader: 1-4" checked={formData.readerRemu === "RemuReader: 1-4"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">1 to 4 $ </label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="readerRemu" value="RemuReader: 5-9" checked={formData.readerRemu === "RemuReader: 5-9"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">5 to 9 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="readerRemu" value="RemuReader: 10-15" checked={formData.readerRemu === "RemuReader: 10-15"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">10 to 15 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="readerRemu" value="RemuReader: 15-20" checked={formData.readerRemu === "RemuReader: 15-20"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300"> 15 to 20 $ </label>
                                    </div>
                                </div>
                            </div>
                        )}


                        {(formData.status === "Disagner" || (formData.status === "EditionHome" && formData.homeStatus.includes("Disagner"))) && (
                            <div>
                                <span className='text-white ' > In what salary bracket do you work as a disagner? </span>
                                <div className="flex justify-between mb-4 mt-2">
                                    <div className="flex items-center me-4">
                                        <input id="inline-radio" type="radio" name="disagnRemu" value="RemuDisagner: 10-20" checked={formData.disagnRemu === "RemuDisagner: 10-20"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">10 to 20 $ </label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="disagnRemu" value="RemuDisagner: 20-30" checked={formData.disagnRemu === "RemuDisagner: 20-30"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">20 to 30 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="disagnRemu" value="RemuDisagner: 30-40" checked={formData.disagnRemu === "RemuDisagner: 30-40"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">30 to 40 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="disagnRemu" value="RemuDisagner: 40-50" checked={formData.disagnRemu === "RemuDisagner: 40-50"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">40 to 50 $</label>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(formData.status === "Printer" || (formData.status === "EditionHome" && formData.homeStatus.includes("Printer"))) && (
                            <div>
                                <span className='text-white ' > In what salary bracket do you work as a printer? </span>
                                <div className="flex justify-between mb-4 mt-2">
                                    <div className="flex items-center me-4">
                                        <input id="inline-radio" type="radio" name="printerRemu" value="RemuPrinter: 10-15" checked={formData.printerRemu === "RemuPrinter: 10-15"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">10 to 15 $ </label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="printerRemu" value="RemuPrinter: 15-20" checked={formData.printerRemu === "RemuPrinter: 15-20"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300">15 to 20 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="printerRemu" value="RemuPrinter: 20-25" checked={formData.printerRemu === "RemuPrinter: 20-25"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300"> 20 to 25 $</label>
                                    </div>
                                    <div className="flex items-center me-4">
                                        <input id="inline-checked-radio" type="radio" name="printerRemu" value="RemuPrinter: 25-30" checked={formData.printerRemu === "RemuPrinter: 25-30"} onChange={handleRadioChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="inline-checked-radio" className="ms-2 text-sm font-medium text-white dark:text-gray-300"> 25 to 30 $</label>
                                    </div>
                                </div>

                            </div>
                        )}

                        {formData.status === "EditionHome" && (
                            <div>
                                <div className="grid gap-6 mb-6  md:grid-cols-2">
                                    <div >
                                        <label htmlFor="companyName" className="block mb-2 text-sm font-medium text-white dark:text-white">Company name: </label>
                                        <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="company" required />
                                    </div>
                                    <div >
                                        <label htmlFor="localname" className="block mb-2 text-sm font-medium text-white dark:text-white">Locality company: </label>
                                        <input type="text" id="localname" name="companyLocal" value={formData.companyLocal} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="locality" required />
                                    </div>
                                </div>
                                <div className="dark:bg-gray-800">
                                    <label htmlFor="presentCompany" className=" block mb-2 text-sm font-medium text-white dark:text-white">Company presentation:</label>
                                    <textarea id="presentCompany" name="companyPresent" value={formData.companyPresent} onChange={handleTextareaChange} className="w-full  py-2 h-40  px-4  mb-4 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Give a concise and precise presentation of the company you represent...." required ></textarea>
                                </div>
                                <div className="dark:bg-gray-800">
                                    <label htmlFor="motiv" className=" block mb-2 text-sm font-medium text-white dark:text-white">What made you want to join this community and how did you find out about it ?</label>
                                    <textarea id="motiv" name="motivation" value={formData.motivation} onChange={handleTextareaChange} className="w-full  py-2 h-40  px-4  mb-6 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write a comment..." required ></textarea>
                                </div>
                            </div>
                        )}

                        {formData.status === "Author" && (
                            <div className="dark:bg-gray-800">
                                <label htmlFor="biograpie" className=" block mb-2 text-sm font-medium text-white dark:text-white"> Author biography: </label>
                                <textarea id="biographie" rows={30} cols={50} name="biography" value={formData.biography} onChange={handleTextareaChange} className="w-full  py-2 h-40  px-4  mb-4 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="here we need you to write a biography of your personality..." required ></textarea>
                            </div>
                        )}

                        {(formData.status === "Printer" || formData.status === "Disagner" || formData.status === "CorrectorReader") && (
                            <div>
                                <div className="dark:bg-gray-800">
                                    <label htmlFor="comment" className=" block mb-2 text-sm font-medium text-white dark:text-white">Present yourself in this section in a maximum of 1000 .</label>
                                    <textarea id="comment" name="yourSelf" value={formData.yourSelf} onChange={handleTextareaChange} className="w-full  py-2 h-40  px-4  mb-4 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write a comment..." required ></textarea>
                                </div>
                                <div className="dark:bg-gray-800">
                                    <label htmlFor="motivation" className=" block mb-2 text-sm font-medium text-white dark:text-white">What made you want to join this community and how did you find out about it ?</label>
                                    <textarea id="motivation" name='motivation' value={formData.motivation} onChange={handleTextareaChange} className="w-full  py-2 h-40  px-4  mb-6 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write a comment..." required ></textarea>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-around">
                            <button type="reset" className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>

                            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-bleu-700 dark:focus:ring-blue-800">Submit</button>
                        </div>

                    </form>
                </div>

                {idPersonDto.length > 0 && (
                    <div className='mt-4'>
                        <AccountForm idPer={idPersonDto} />
                    </div>
                )}




            </div>


        </>
    );
};

export default FormInscription;
