import React from 'react';
import CardProj from './CardProj';
import { BookInterface } from '../Interface/Book';
interface CurrentProjProps {
    books: BookInterface[];
}

const CurrentProj: React.FC<CurrentProjProps> = ({ books }) => {
    return (
        <>
            <div className='w-full max-w-7x mt-20 mx-auto h-60'>
                <h3 className="text-center font-bold text-5xl">
                    Some projects currently underway
                </h3>
                <p className=' mt-10  text-justify font-semibold text-xl '>
                    Below are a few projects by authors who have shown their confidence in us and taken advantage of this unique opportunity,
                    which is currently being contributed to by all the members of the community who have been seduced by the extracts from the books proposed by each of our excellent authors.
                    You can also take part in the contribution for the realization of one of the books below and to profit from a physical and numerical book (in version NFT) at the end of the edition of the aforesaid book.
                </p>
            </div>


            <div className="grid grid-cols-2  justify-between mt-6 gap-5  ">
                {books.map(book  => (
                    <div className=" flex flex-col-2  border-4 p-2 border-none  max-w-4xl rounded-lg bg-indigo-100  dark:border-gray-700 mt-1" >
                        <CardProj key={book.idBookDto} book={book} />
                    </div>
                    ))
                }
            </div>


        </>
    );
};

export default CurrentProj;