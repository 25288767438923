import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CurrentProj from './CurrentProj';
import axios from 'axios';

const Main = () => {

    const [books, setBooks] = useState([]);
    const [errorList, setErrorList] = useState<any>();
    const url = process.env.REACT_APP_SPRING_API_URL;

    useEffect(() => {
        const listBooks = async () => {
            try {
                const response = await axios.get(`${url}ListBooks`);
                setBooks(response.data);
            } catch (error) {
                setErrorList(error);
                console.error("error getting list book: ", error);
            };
        };

        listBooks();

    }, []);


    return (
        <>
            <div className="sm:ml-full ">
                <div className=" h-auto p-4 border-2 border-none dark:border-gray-700 mt-1"  >
                    <h3 className="text-center h-28 pt-10 font-bold text-5xl">
                        Free your mind and inspire the world
                    </h3>
                    <div className="grid grid-cols-3 mt-6 gap-6  ">
                        <div className="  border-4 p-4 border-gray-200 border-r-blue-300 rounded-lg bg-indigo-100  dark:border-gray-700 mt-1">
                            <p className='text-justify font-normal text-xl'>
                                With the power to inspire, modify and even influence the thinking of readers, books are one of the most effective ways of imparting knowledge and creating a lasting impact on people's lives. If you want to tell a story, share knowledge to establish your credibility in your field of expertise or fulfil your dream of writing a book, you've come to the right place.
                            </p>
                        </div>

                        <div className="border-4 p-4 border-gray-100 border-r-fuchsia-300 rounded-lg bg-violet-100 dark:border-gray-700 mt-1">
                            <p className='text-justify font-normal text-xl'>
                                Pursuing this ambition can bring you a sense of personal fulfilment and satisfaction.
                                These days in the world of books, you have the opportunity to control the integrity of your message in the Book and even make a living from your art through the Rhcoinx protocol.
                            </p>
                        </div>

                        <div className="border-4 p-4 border-gray-200 border-r-emerald-200 rounded-lg bg-slate-100  dark:border-gray-700 mt-1">
                            <p className='text-justify font-normal text-xl'>
                                In fact, the Rhcoinx protocol is a very powerful tool based on blockchain technologies to guarantee the security, immutability, integrity and royalty of your books, in particular by allowing the community to take charge of the entire book publishing process through pre-orders.
                                So if you are a professional author or a beginner in the trade, don't miss this opportunity to make your book known to the whole world.
                            </p>
                        </div>

                    </div>

                    <div className="w-full max-w-2xl rounded-tr-lg rounded-bl-lg  h-24 mx-auto p-4 mt-16 shadow dark:border text-white group bg-gradient-to-br from-purple-800 to-blue-500">
                        < NavLink to="/Fycoinx/Connexion">  <h5 className=" pt-2 text-center text-2xl font-bold"> Get your book published using Blockchain by clicking here </h5> </NavLink>
                    </div>

                </div>
               
               <CurrentProj books={books}/>
               


            </div>
        </>
    );
};

export default Main;