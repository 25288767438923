import axios from "axios";
import { GetIp } from "../Interface/GetIp";
import { UpIp } from "../Interface/UpIp";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { NftDeployObj } from "../Interface/NftDeploy";

const url = process.env.REACT_APP_SPRING_API_URL;

export const getListToStr = async (list: string[]): Promise<string> => {
    try {
        const str = await axios.post(`${url}Contract/Nft/ListToString`, list);
        return str.data;
    } catch (error) {
        throw Error;
    }
};
export const deploy = async (ObjDepl: NftDeployObj) => {
    try {
        const deploy = await axios.post(`${url}Contract/Nft/Deploy`, ObjDepl);      
        return deploy;
    } catch (error) {
        console.log(error);
    }
}

export const getStorage = async (): Promise<any> => {
    try {
        const storage = await axios.get(`${url}Contract/Nft/Storage`);
        return storage.data;
    } catch (error: any) {
        console.log(error);
    }
}

export const getValueWei = async (value: number): Promise<number> => {
    try {
        const response = await axios.get(`${url}Contract/Nft/GetWei?amount=${value}`);
        return response.data;
    } catch (error) {
        throw Error;
    }
}

export const getNftByIpfs = async (pass: string, uri: string, urlPath: string): Promise<File> => {
    try {
        const getIp: GetIp = { pwd: pass, hashIp: uri, outPath: urlPath }
        const response = await axios.post(`${url}Contract/Nft/GetIp`, getIp );
        return response.data;
    } catch (error) {
        throw Error;
    }
};

export const getToReadFile = async (pass: string, uri: string): Promise<File> => {
    try {
        const response = await axios.get(`${url}Contract/Nft/ReadFile`, {
            params: {
                pass: pass,
                hash: uri
            },
            responseType: 'blob'
        });
        return new File([response.data], 'NftBook.pdf', { type: 'application/pdf' });
    } catch (error: any) {
        console.error('Error fetching file:', error);
        throw new Error('File not exist');
    }
};

export const upNftByIpfs = async (pass: string, urlInput: string): Promise<any> => {
    const upIp: UpIp = { pwd: pass, inputPath: urlInput };
    const response = await axios.post(`${url}Contract/Nft/UplIp`, upIp);
    return response.data;
};
export const getAbi = async (): Promise<any> => {
    try {
        const abi = await axios.get(`${url}Contract/Nft/AbiFile`);
        return abi.data;
    } catch (error) {
        throw Error;
    }
};

export const getWeb3 = async (): Promise<Web3 | undefined> => {
    const provider: any = await detectEthereumProvider();
    if (provider) {
        const web3Instance = new Web3(provider);
        await provider.request({ method: "eth_requestAccounts" });
        return web3Instance;
    }
    throw new Error("Ethereum provider not found");
};

export const getEthBalance = async (address: string, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const respBalance: number = await contract.methods.getBalance(address).call();
         //   console.log("your eth balance is : ", respBalance);
            return respBalance;
        } catch (error: any) {
            console.log(error);
        }
    }

};

export const getAddress = async (idToken: number, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const addressResp = await contract.methods.getCAid(idToken).call();
         //   console.log('address contract by token id ' + addressResp)
            return addressResp;
        } catch (error: any) { }
        throw Error;
    }
};
export const getUri = async (idToken: number, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi()
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const uriAddress = await contract.methods.getUrid(idToken).call();
         //   console.log('uri address ' + uriAddress);
            return uriAddress;
        } catch (error) {
            throw Error;
        }
    }
}

export const getAutName = async (idToken: number, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const name = await contract.methods.getANid(idToken).call();
          //  console.log(name);
            return name;
        } catch (error) {
            throw Error;
        }
    }
}

export const getOwnerToken = async (idToken: number, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const ownert = await contract.methods.getOwnId(idToken).call();
         //   console.log('owner token ' + ownert);
            return ownert;
        } catch (error) {
            throw Error;
        }
    }
}
export const getContractOwner = async (contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const owner = await contract.methods.owner().call();
        //    console.log('owner contract ' + owner);
            return owner;
        } catch (error) {
            throw Error;
        }
    }
}

export const setUri = async (idToken: number, uri: string, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];
            const owner = await contract.methods.setUrid(idToken, uri).send({ from: account });
         //   console.log(owner);
            return owner;
        } catch (error) {
            throw Error;
        }
    }
}


export const mintNft = async (listBook: string[], owner: string, addressContract: string): Promise<any> => {
    const encrypts: string[] = [];
    const uriPromise = listBook.map(async (book) => {
        try {
            const uri = await upNftByIpfs(owner.toLowerCase(), book);
            if (uri)
                encrypts.push(uri);
        } catch (error: any) {
            console.log(error);
        }
    });
    await Promise.all(uriPromise)
    console.log(encrypts)

    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && encrypts.length > 0) {
        console.log('if ', encrypts)
        try {
            const contract = new web3.eth.Contract(abi, addressContract);
            const accounts = await web3.eth.getAccounts();
            const owner = accounts[0];

            const gasEstimate = await web3.eth.estimateGas({
                from: owner,
                to: addressContract,
                data: contract.methods.mintNftBooks(encrypts).encodeABI()
            });

            const minted = await contract.methods.mintNftBooks(encrypts).send({
                from: owner,
                gas: gasEstimate as unknown as string
            }); 

            console.log('minted ', minted)
            alert('successfully mint')
            return minted;
        } catch (error: any) {
            console.log("error  during send transaction", error);
        }
    }
};

export const ownerTransfer = async (from: string, to: string, tokenId: number, contractAddress: string): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    const address = await getAddress(tokenId, contractAddress);
    console.log('compared add ' , address)
    console.log('compared contract add ' , contractAddress);
    if (web3 && abi && address.toLocaleUpperCase() === contractAddress.toLocaleUpperCase()) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];

            console.log('owner token befor ' , await getOwnerToken(tokenId, contractAddress));
            const estimateGas = await web3.eth.estimateGas({
                from: account,
                to: contractAddress,
                data: contract.methods.ownerTransferFrom(account, to, tokenId).encodeABI()
            });

            const ownerSender = await contract.methods.ownerTransferFrom(account, to, tokenId).send({
                from: account,
                gas: estimateGas as unknown as string
            })

            console.log(ownerSender);

            const list: string[] = [await getAutName(tokenId, contractAddress), await getOwnerToken(tokenId, contractAddress), contractAddress, tokenId.toString()];
            console.log('list: ' + list);
          //   console.log('getString ' + await getListToStr(list))
           const str = await getListToStr(list);
           console.log('get list:  ' + str);
          // 
            await getNftByIpfs(from.toLowerCase(), await getUri(tokenId, contractAddress), await getStorage());
        
            //const uri = await upNftByIpfs(await getListToStr(list), await getStorage());
            const uri = await upNftByIpfs('yous', await getStorage());

            console.log('uri ' + uri);

            await setUri(tokenId, uri, contractAddress);

            console.log('owner token after ' , await getOwnerToken(tokenId, contractAddress))

            console.log('congratulations on your successful transfer of Nft from ' , from + ' to ' + to);
            return tokenId;

        } catch (error: any) {
            throw new Error(error.message);
        }
    }
}

export const buyNFtBook = async (receive: string, contractAddress: string, idToken: number, amount: number): Promise<any> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const contract = new web3.eth.Contract(abi, contractAddress);
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];
            const list = [await getAutName(idToken, contractAddress), await getOwnerToken(idToken, contractAddress), contractAddress, idToken.toString()];
            const v1 = await getListToStr(list);
            const weiAmount = web3.utils.toWei(amount, 'ether');
            const gas = await contract.methods.BuyNftBook(receive, idToken, web3.utils.toWei(amount, 'ether')).estimateGas({
                from: account,
                value: weiAmount
            });
            const result = await contract.methods.BuyNftBook(receive, idToken, web3.utils.toWei(amount, 'ether')).send({
                from: account,
                value: weiAmount,
                gas: gas.toString()
            });
            console.log(result);
            await getNftByIpfs(v1, await getUri(idToken, contractAddress), await getStorage());
            const L2 = [await getAutName(idToken, contractAddress), await getOwnerToken(idToken, contractAddress), contractAddress, idToken.toString()];
            const uri = await upNftByIpfs(await getListToStr(L2), await getStorage());
            await setUri(idToken, uri, contractAddress);
            return "successfully puchcase Nft Token";
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
}


export const getNftIpfs = async (pass: string, uri: string, urlPath: string): Promise<File> => {
    try {
        const getIp: GetIp = { pwd: pass, hashIp: uri, outPath: urlPath || "" }; // Envoi d'une chaîne vide si urlPath n'est pas défini
        const response = await axios.post(`${url}Contract/Nft/GetIp`, getIp, { responseType: 'blob' });
        return response.data;
    } catch (error) {
        throw Error;
    }
};

export const readBook = async (tokenId: number, contractAddress: string): Promise<Blob> => {
    const web3 = await getWeb3();
    const abi = await getAbi();
    if (web3 && abi) {
        try {
            const list: string[] = [
                await getAutName(tokenId, contractAddress),
                await getOwnerToken(tokenId, contractAddress),
                contractAddress,
                tokenId.toString()
            ];
            const str = 'yous';            
            const uri = await getUri(tokenId, contractAddress);
            const nftFile = await axios.get(`${url}Contract/Nft/ReadFile`,{
                params: {
                    pass: str,
                    hash: uri
                },
               responseType: 'arraybuffer' 
            });
            return new Blob([nftFile.data], { type: 'application/pdf' } );
        } catch (error: any) {
            console.error('Error fetching file content:', error);
            throw new Error(error.message);
        }
    } else {
        throw new Error('Web3 or ABI not available');
    }
};
