import React from 'react';
import Navigation from '../Components/Navigation';
import Headers from '../Components/Header';
import Fycoinx from '../Components/Contract/Fycoinx';
import DeployNft from '../Components/Contract/DeployNft';

const Deployers = () => {
    return (
        <>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <Headers />
                </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <Navigation />
            </aside>

            <div className="p-6 sm:ml-64  ">
                <h1 className="text-xl font-bold text-center mt-20 leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white">
                    Welcome to the deployed form page
                </h1>
                <div className='flex flex-row'>
                    <div className='w-full'>
                        <Fycoinx />
                    </div>
                    <div className='w-full'>
                        <DeployNft />
                    </div>


                </div>

            </div>


            <footer>
                <div className="px-4 py-6 md:flex md:items-center md:justify-between">

                    <span className="text-sm text-white dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">Rhcoinx</a>. All Rights Reserved.</span>
                </div>
            </footer>

        </>
    );
};

export default Deployers;