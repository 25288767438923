import React from 'react';
import Navigation from '../Components/Navigation';
import Headers from '../Components/Header';
import Footer from '../Components/Footer';

const MarketPlace = () => {
    return (
        <>            
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <Headers/>
            </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <Navigation/>
            </aside>
            
            <div className="p-4 sm:ml-64">
              <h1 className='mt-72'>ifzhifjzjfopjezf</h1>
               
             </div>

                 <button className="relative inline-flex items-center justify-center p-0.5 mt-20 ml-14 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                         Purple to pink
                    </span>
                </button>
               


            <footer>
                <div className="px-4 py-6 md:flex md:items-center md:justify-between">
                    
                    <span className="text-sm text-white dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">Rhcoinx</a>. All Rights Reserved.</span>                    
                </div>
            </footer>

      </>  
    );
};

export default MarketPlace;