// src/pages/BookDetailPage.tsx
import React from 'react';
import Detail from '../Components/Detail';
import { useNavigate, useParams } from 'react-router-dom';


const DetailPage: React.FC = () => {
    const navigate = useNavigate();
    const { idBookDto } = useParams<{ idBookDto: string }>();
    const url = "/Fycoinx/Book/Contribution/";

    const handleGoToStatePage = () => {
        navigate(`${url}State/${idBookDto}`);
        console.log('id book', idBookDto);
    }

    const handleContributionClick = () => {
        navigate(`${url}${idBookDto}`);
       }

    return (
        <div>
            <div className='flex flex-row justify-between'>
                <h1 className="text-2xl font-bold p-4">Book Detail</h1>
                <button type="button" onClick={handleContributionClick} className=" relative inline-flex items-center justify-center p-0.5 mt-3 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
                    <span className='relative px-5 py-2.5 text-2xl  text-white'>
                    Make a contribution
                    </span>
                </button>
                <button type="button" onClick={handleGoToStatePage} className=" relative inline-flex items-center justify-center p-0.5 mt-3 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
                    <span className='relative px-5 py-2.5 text-xl transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0'>
                        click here to get state
                    </span>
                </button>
            </div>

            <Detail />
        </div>
    );
};

export default DetailPage;
