import React, { useEffect, useState } from 'react';
import Headers from '../Components/Header'
import Navigation from '../Components/Navigation';
import CurrentProj from '../Components/CurrentProj';
import axios from 'axios';

const CurrentProject: React.FC = () => {
    const [books, setBooks] = useState([]);
    const [errorList, setErrorList] = useState<any>();
    const url = process.env.REACT_APP_SPRING_API_URL;

    useEffect(() => {
        const listBooks = async () => {
            try {
                const response = await axios.get(`${url}ListBooks`);
                setBooks(response.data);
            } catch (error) {
                setErrorList(error);
                console.error("error getting list book: ", error);
            };
        };

        listBooks();

    }, []);


    return (
        <>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <Headers />
                </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <Navigation />
            </aside>

            <div className="p-4 sm:ml-64">
                <h1 className="text-2xl font-bold p-4">Book List</h1>
                <CurrentProj books={books} />
            </div>


            <footer>
                <div className="px-4 py-6 md:flex md:items-center md:justify-between">

                    <span className="text-sm text-white dark:text-gray-300 sm:text-center">© 2023 <a href="https://flowbite.com/">Rhcoinx</a>. All Rights Reserved.</span>
                </div>
            </footer>

        </>
    );
};

export default CurrentProject;