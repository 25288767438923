import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios';
import React, { ChangeEvent, useState, useEffect } from 'react';
import Web3 from 'web3';


interface formDeploy {
    ownerAddress: string;
    initialSupply: number;
    decimals: number
}
interface BurnToken {
    _account: string;
    _amount: number;
}

interface ApproveToken {
    _spender: string;
    _amount: number;
}

const Fycoinx = () => {
    const [errorDeploy, setErrorDeploy] = useState('');
    const [contractAddress, setContractAddress] = useState<string>('');
    const [formData, setFormData] = useState<formDeploy>({
        ownerAddress: '',
        initialSupply: 0,
        decimals: 0
    });
    const initDeploy = {
        ownerAddress: '',
        initialSupply: 0,
        decimals: 0
    };

    const handleInputeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }
    function reset() {
        setFormData(initDeploy);
    }

    const url = process.env.REACT_APP_SPRING_API_URL

    const handleSubmitDeploy = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorDeploy('');
        try {
            const response = await axios.post(`${url}Contract/Fycoinx/Deployment?ownerAddres=${formData.ownerAddress}&&initialSupply=${formData.initialSupply}&&decimals=${formData.decimals}`);
            if (response.data) { }
            setContractAddress(response.data);
            alert(response.data);
            reset();
        } catch (error: any) {
            setErrorDeploy(error);
            console.log(error);
            alert('error deploying contract:' + errorDeploy);

        }
    }
    const [formBurn, setFormBurn] = useState<BurnToken>({
        _account: '',
        _amount: 0
    });

    const initBurn = {
        _account: '',
        _amount: 0
    };

    function resetBurn() {
        setFormBurn(initBurn);
    }

    const [formApprove, setFormApprove] = useState<ApproveToken>({
        _spender: '',
        _amount: 0
    })

    const initApprove = {
        _spender: '',
        _amount: 0
    }

    function resetApprove() {
        setFormApprove(initApprove);
    }

    const [account, setAccount] = useState<string>('');
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [abi, setAbi] = useState<any>(null);
    const [errorBurn, setErrorBurn] = useState<any>(null);
    const [errorApprove, setErrorApprove] = useState<any>();
    const [errorWallet, setErrorWallet] = useState<any>();

    useEffect(() => {
        const initWallet = async () => {
            try {
                const provider: any = await detectEthereumProvider();
                if (provider) {
                    const instanceWeb3 = new Web3(provider);
                    setWeb3(instanceWeb3);

                    await provider.request({ method: 'eth_requestAccounts' });
                    const accounts = await instanceWeb3.eth.getAccounts();
                    setAccount(accounts[0]);

                    const abiContract = await axios.get(`${url}Contract/Fycoinx/AbiFile`);
                    setAbi(abiContract.data);
                    const addressFyx = await axios.get(`${url}Contract/Fycoinx/Address`);
                    setContractAddress(addressFyx.data);
                }
                else {
                    console.log('your metamask is not connect or not installing in your computer');

                }
            } catch (error: any) {
                setErrorWallet(error.message);
                console.error("Error initializing wallet:", error);
            }
        };
        initWallet();
    }, []);

    const handleInputChangeBurn = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormBurn({ ...formBurn, [name]: value });
    }

    const handleBurnToken = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!web3 || !abi) {
            console.error('web3 and ABI is not initialized');
            return;
        }
        const contract = new web3.eth.Contract(abi, contractAddress);
        try {
            const data = contract.methods.burnTokenFyX(formBurn._account, formBurn._amount, web3.utils.toWei(formBurn._amount.toString(), 'ether')).encodeABI();
            const burn = {
                from: account,
                to: contractAddress,
                value: '0',
                data: data as unknown as string
            }
            console.log('Pending transaction burn hash', burn);
            await web3.eth.sendTransaction(burn);
            alert('successfully burn Fycoinx tokens transaction');
            resetBurn();
        } catch (error) {
            setErrorBurn(error);
            console.error('error during token buy', errorBurn);
        }
    }

    const handleChangeInputApprove = (event: ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;
        setFormApprove({ ...formApprove, [name]: value });
    }
    const handleApproveToken = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!web3 || !abi) {
            console.error('web3 and ABI is not initialized');
            return;
        }
        const contract = new web3.eth.Contract(abi, contractAddress)
        try {
            const data = contract.methods.approve(formApprove._spender, formApprove._amount, web3.utils.toWei(formApprove._amount.toString(), 'ether')).encodeABI();
            const approve = {
                from: account,
                to: contractAddress,
                value: '0',
                data: data as unknown as string
            }
            console.log('Pending transaction approve hash', approve)
            await web3.eth.sendTransaction(approve);
            alert('successfully buy Fycoinx tokens transaction');
            resetApprove();

        } catch (error: any) {
            setErrorApprove(error);
            console.error('error during token buy', error)
        }

    }

    return (
        <>          
            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300 w-1/2 mt-6  items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center font-semibold text-3xl mt-6"> Deploy Tokens </h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitDeploy}>
                        <div className="relative z-0 w-full mb-5 mt-8 group">
                            <input type="text" name="ownerAddress" value={formData.ownerAddress} onChange={handleInputeChange} id="owner" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="owner" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Owner Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="initialSupply" value={formData.initialSupply} onChange={handleInputeChange} id="supply" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="supply" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Initial Supply :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="decimals" value={formData.decimals} onChange={handleInputeChange} id="decimal" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="decimal" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Decimals :</label>
                        </div>

                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Deploy </button>

                        </div>
                    </form>

                </div>

            </div>


            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/2 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center font-semibold text-3xl mt-6"> Burn Token </h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleBurnToken}>
                        <div className="relative z-0 w-full mt-7 mb-5 group">
                            <input type="text" name="_account" value={formBurn._account} onChange={handleInputChangeBurn} id="address" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="address" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address to burn :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="_amount" value={formBurn._amount} onChange={handleInputChangeBurn} id="token" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="token" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount to burn :</label>
                        </div>

                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Burn </button>

                        </div>
                    </form>

                </div>

            </div>

            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/2 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center font-semibold text-3xl mt-6"> Approve Tokens </h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleApproveToken}>
                        <div className="relative z-0 w-full mt-7 mb-5 group">
                            <input type="text" name="_spender" value={formApprove._spender} onChange={handleChangeInputApprove} id="address" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="address" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Address to approve :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="_amount" value={formApprove._amount} onChange={handleChangeInputApprove} id="amount" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="amount" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Amoun to approve  :</label>
                        </div>


                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Approve </button>

                        </div>
                    </form>

                </div>

            </div>


        </>
    );
};

export default Fycoinx;