import React, { ChangeEvent, useEffect, useState } from 'react';
import '../Styles/account.css'
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

interface AccountForm {
    picture: string;
    userName: string;
    email: string;
    addressWallet: string;
    pwd: string;
    confirmPwd: string;
    approbation: boolean;
    idPersonDto: string;
}
interface Props {
    idPer: string;
}
interface PreparedAccount {
    [key: string]: any;
    picture: string;
    userName: string;
    email: string;
    addressWallet: string;
    pwd: string;
    idPersonDto: string;
}
interface Person {
    idPersonDto: "string";
    firstNamePersonDto: "string";
    lastNamePersonDto: "string";
    nationalityPersonDto: "string";
    phonePersonDto: "string";
    profile: string[];
    dateDto: string;
    
}


const AccountForm: React.FC<Props> = ({ idPer }) => {

    const [photo, setPhoto] = useState<string | ArrayBuffer | null>(null);

    const [formData, setFormData] = useState<AccountForm>({
        picture: '',
        userName: '',
        addressWallet: '',
        email: '',
        pwd: '',
        confirmPwd: '',
        approbation: false,
        idPersonDto: ''

    });

    const [person, setPerson] = useState<Person | null>(null);
    const [error, setError] = useState('');
    const [getError, setGetError] = useState('');
    const url = process.env.REACT_APP_SPRING_API_URL;
    console.log("url",url);


    const initialAccount = {
        picture: '',
        userName: '',
        addressWallet: '',
        email:'',
        pwd: '',
        confirmPwd: '',
        approbation: false,
        idPersonDto: ''
    }


    useEffect(() => {
        const getPerson = async () => {
            try {
                const response = await axios.get(`${url}Person/${idPer}`);
                setPerson(response.data);
            } catch (error: any) {
                setGetError(error)
            }
        };
        if (idPer) { getPerson(); }

    }, [idPer]);

    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //  const selectedPhoto = event.target.files && event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = (e) => {              
                setPhoto(reader.result as string);
                setFormData({...formData, picture: reader.result as string });           
            };
            reader.readAsDataURL(file);
        }
    };

        
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {      
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const preparedDataSubmissionAccount = (data: AccountForm): PreparedAccount => {
        return {
            picture: data.picture,
            userName: data.userName,
            addressWallet: data.addressWallet,
            email: data.email,
            pwd: data.pwd,
            idPersonDto: idPer
        };
    };

        const navigate = useNavigate();
        const handleAccountSubmit = async (event: React.FormEvent) => {
            event.preventDefault();
            if (formData.pwd !== formData.confirmPwd) {
                setError('les mot de passe sont incorrect');
                alert('mot de passe incorrect');
                return;
            }
                console.log(formData);
            try {
                const response = await axios.post(`${url}AddAccount?idPerson=${idPer}`,
                preparedDataSubmissionAccount(formData));                
                console.log(response.data);              
                setFormData(initialAccount);
                alert('successfuly account created');
                navigate('/Fycoinx/CurrentProject') ;   
            } catch (error: any) {
                console.log(error);
                setError(error.message);
            }

        };
    

        return (
            <>
                <div className="flex flex-col items-center justify-center p-6 space-y-3  space-x-6 px-6 py-1  mb-6 mx-auto  lg:py-0">
                    <div className="w-full  rounded-2xl shadow dark:border md:mt-0 group bg-gradient-to-br from-purple-500 to-pink-500  sm:max-w-md xl:p-0  ">
                        <div className="p-3 space-y-2 md:space-y-3  sm:p-8">
                            <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-white md:text-2xl dark:text-white">
                                Your Account registration form
                            </h1>
                            <form className="space-y-2 md:space-y-2 " onSubmit={handleAccountSubmit}>
                                {person?.profile.includes("Author") && (
                                    <div className='mt-6'>
                                        <label className="block text-sm  font-medium text-white">Downloard the picture:</label>

                                        <div className='flex items-center '>
                                            <input type="file" onChange={handlePhotoChange} accept='image/*' className=" mr-10 w-40 flex items-start  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm  sm:text-sm f" />
                                            {photo && (<div className="ml-10">
                                                <img src={photo as string} alt="Selected" className="mt-2 max-w-md rounded-full w-20 h-20" />
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <label htmlFor="user" className="block mb-2 text-sm font-medium text-white dark:text-white">User Name :</label>
                                    <input type="text" id="user" name="userName" value={formData.userName} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='user name' />
                                </div>
                                <div>
                                    <label htmlFor="wallet" className="block mb-2 text-sm font-medium text-white dark:text-white">Wallet address :</label>
                                    <input type="text" id="wallet" name="addressWallet" value={formData.addressWallet} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="wallet address" />
                                </div>

                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-white dark:text-white">Email :</label>
                                    <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="hello@gamail.com" />
                                </div>

                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-white dark:text-white">Password : </label>
                                    <input type="password" name="pwd" value={formData.pwd} onChange={handleInputChange} id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-white dark:text-white">Confirm password :</label>
                                    <input type="password" id="confirm-password" name="confirmPwd" value={formData.confirmPwd} onChange={handleInputChange} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="terms" aria-describedby="terms" type="checkbox" name='approbation' className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                                    </div>
                                    <div className="ml-3 mb-3 text-sm">
                                        <label htmlFor="terms" className="font-light text-white dark:text-gray-300">I accept the Terms and Conditions </label>
                                    </div>
                                </div>
                                <div className="flex justify-around mt-6">
                                    <button type="reset" className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>

                                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-bleu-700 dark:focus:ring-blue-800">Submit</button>
                                </div>
                               
                            </form>
                        </div>
                    </div>              
                </div>

            </>
        );
    };

    export default AccountForm;