import './App.css';
import React  from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import HomePage from './Pages/HomePage';
import ContributionPage from './Pages/ContributionPage';
import MarketPlace from './Pages/MarketPlace';
import Inscription from './Pages/Inscription';
import AboutUs from './Pages/AboutUs';
import ErrorMessag from './Pages/ErrorMassag';
import BookReader from './Pages/BookReader';
import Project from './Pages/Project';
import Connexion from './Pages/Connexion';
import CurrentProject from './Pages/CurrentProject';
import Deployers from './Pages/Deployers';
import Transaction from './Pages/Transaction';
import DetailPage from './Pages/DetailPage';
import StatePage from './Pages/StatePage.tsx';

function App() {
    return (
  <>
  <BrowserRouter>
    <Routes>
      <Route path='/Fycoinx/Book/Detail/:idBookDto' element={<DetailPage/>}/>
      <Route path='/Fycoinx/Book/Contribution/:idBookDto' element={<ContributionPage/>}/>
      <Route path='/Fycoinx/Book/Contribution/State/:idBookDto' element={<StatePage/>}/>
      <Route path='/Fycoinx/HomePage' element={<HomePage/>}/>      
      <Route path='/Fycoinx/MarketPlace'  element={<MarketPlace/>}/>
      <Route path='/Fycoinx/Inscription' element={<Inscription/>}/>
      <Route path='/Fycoinx/BookReader' element={<BookReader/>}/>
      <Route path='/Fycoinx/AboutUs' element={<AboutUs/>}/>
      <Route path='/Fycoinx/CreatProject' element={<Project/>}/>
      <Route path='/Fycoinx/Deployers' element = {<Deployers/>} />
      <Route path='/Fycoinx/CurrentProject' element={<CurrentProject/>}/>
      <Route path='/Fycoinx/Connexion' element={<Connexion/>}/>
      <Route path='/Fycoinx/Transaction' element ={<Transaction/>}/>
      <Route path='/' exact element={<HomePage/>}/>
      <Route path='*' element={<ErrorMessag/>}/>
    </Routes>
  </BrowserRouter>
     
      

  </>
  );
}

export default App;


  
