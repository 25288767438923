import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Main from '../Components/Main';
import Footer from '../Components/Footer';


const componentName = () => {
    return (

        <>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <Header />
                </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <Navigation />
            </aside>

            <div className="p-4 sm:ml-64 "   >
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg  dark:border-gray-700 mt-14" style={{ backgroundSize: 'cover', maxHeight: '100vh', backgroundImage: 'url("homeImg.jpg")' }} >
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div className="flex items-center justify-center h-72 rounded  dark:bg-gray-800">
                            <h2 className="text-3xl text-gray-900 dark:text-gray-700 text-center font-bold ml-3" >
                                Tokenomics: A BlockChain tool for authors </h2>
                        </div>

                    </div>
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div className="flex items-center justify-center h-28, backgroundPosition: 'center'  mb-4 rounded dark:bg-gray-800">
                            <p className="text-2xl text-gray-700 dark:text-gray-600 text-center ml-2">
                                The fintech world opens its doors to you:
                                Become the owner of your thoughts through the RhCoinx protocol.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="flex items-center justify-between rounded h-56 dark:bg-gray-800">
                            <NavLink to={"/Fycoinx/Inscription"}>
                                <span className="text-white bg-blue-700  hover:bg-blue-800 font-medium 
                                rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 cursor-pointer text-center ">
                                    CREATE ACCOUNT
                                </span>
                            </NavLink>

                            <NavLink to={"/Fycoinx/CreatProject"}>
                                <span className="text-white bg-blue-700 hover:bg-blue-800 
                                font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 cursor-pointer text-center">
                                    CREATE PROJECT
                                </span>
                            </NavLink>

                            <NavLink to={"/Fycoinx/CurrentProject"} >
                                <span className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none
                            font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 cursor-pointer text-center">
                                    CURRENT PROJET
                                </span>
                            </NavLink>
                        </div>

                    </div>

                </div>
                <div className="grid-cols-1 gap-1">
                    <Main />
                </div>

                <Footer />

            </div>



        </>

    );
};

export default componentName;