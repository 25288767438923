import React from 'react';
import Headers from '../Components/Header';
import Navigation from '../Components/Navigation';
import FycoinxForm from '../Forms/Contract/FycoinxForm';

const Transaction = () => {
    return (
        <>
        <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <Headers/>
        </div>
        </nav>

        <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
            <Navigation/>
        </aside>
        
        <div className="p-4 sm:ml-64">
            <FycoinxForm/>
        </div>
            
       
        

    </>
    );
};

export default Transaction;