import axios from "axios";
import { BookNft } from "../Interface/NftBook";

const url = process.env.REACT_APP_SPRING_API_URL;

export const addBookNft = async (idBook : string, objNft : BookNft) : Promise<BookNft> => {
    const response = await axios.post(`${url}AddNftBook?idBook=${idBook}`, objNft);
        return response.data;
}

export const updateBookNft = async (objNft : BookNft, idObjNft: string) : Promise<BookNft> =>{
    const resp = await axios.patch(`${url}Update/NftBook/${idObjNft}`, objNft);
    return resp.data;
}
export const getNftBookByAddress = async (contractAddress: string) : Promise<BookNft[]> =>{
    const nftBook = await axios.get(`${url}GetNftBook/By/ContractAddress?address=${contractAddress}`);
    return nftBook.data;
}

export const getNftOwnerToken = async (ownerNft: string ) : Promise<BookNft[]> => {
    const book = await axios.get(`${url}GetNftBook/By/OwnerNft?ownerToken=${ownerNft}`)
    return book.data;
}