import React from 'react';
import { NavLink } from 'react-router-dom';

const ConnexionForm = () => {



    return (
        <>

            <div className=" group bg-gradient-to-br from-blue-400 to-green-300   mt-40 w-2/6 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center text-4xl mt-6"> Login form</h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto">
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="email" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">User Name :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="password" name="floating_password" id="floating_password" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label htmlFor="floating_password" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password :</label>
                        </div>
                        <div className='w-full  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Validate</button>

                        </div>


                    </form>

                    <h5 className=' text-center mb-5'> {'<'}---------------------{'>'} ou {'<'}---------------------{'>'}</h5>


                    <NavLink to="/Fycoinx/Inscription"> <h5 className='text-lg text-center mb-6 font-bold text-blue-700'>  Click here to subscribe ! </h5> </NavLink>


                </div>

            </div>


        </>
    );
};

export default ConnexionForm;