import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Web3 from 'web3';



interface SendToken {
    _from: string;
    _to: string;
    _amount: number;
}
interface BuyToken {
    _to: string;
    _amount: number
}

interface PreparedBuying {
    [key: string]: any;
    address: string;
    amount: number;
}

const FycoinxForm = () => {
    const [formSend, setFormSend] = useState<SendToken>({
        _from: '',
        _to: '',
        _amount: 0
    });

    const [formBuy, setFormBuy] = useState<BuyToken>({
        _to: '',
        _amount: 0
    })

    const [account, setAccount] = useState<string>('');
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [abi, setAbi] = useState<any>(null);
    const [errorSend, setErrorSend] = useState<any>();
  
    const [contractAddress, setContractAddress] = useState<string>('');

    const url = process.env.REACT_APP_SPRING_API_URL

    useEffect(() => {
        const initWallet = async () => {
            try {
                const provider: any = await detectEthereumProvider();
                if (provider) {
                    const web3Instance = new Web3(provider);
                    setWeb3(web3Instance);

                    await provider.request({ method: 'eth_requestAccounts' });
                    const accounts = await web3Instance.eth.getAccounts();
                    setAccount(accounts[0]);
                    //console.log(account);                   

                    const abiContract = await axios.get(`${url}Contract/Fycoinx/AbiFile`);
                    setAbi(abiContract.data);

                    const addressFyx = await axios.get(`${url}Contract/Fycoinx/Address`);
                    setContractAddress(addressFyx.data);

                }
                else {
                    console.log('your metamask is not connect or not installing in your computer !');
                    alert('your metamask is not connect or not installing in your computer');
                }
            } catch (error: any) {
                console.error("Error initializing wallet:", error);
            }
        };
        initWallet();
    }, []);

    const handleInputeChangeSend = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormSend({ ...formSend, [name]: value });
    }

        const init: SendToken = {
            _from: '',
            _to: '',
            _amount: 0
        }
    
    function resetSend() {
        setFormSend(init)
    };

    const handleSendToken = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!web3 || !abi) {
            console.error('web3 and ABI is not initialized');
            return;
        }
        const contract = new web3.eth.Contract(abi, contractAddress);
        console.log('Preparing transaction...');
        try {
            const data = contract.methods.SendTokenFyX(formSend._from, formSend._to, formSend._amount, web3.utils.toWei(formSend._amount.toString(), 'ether')).encodeABI();
            const sender = {
                from: account,
                to: contractAddress,
                value: '0',
                data: data as unknown as string
            };
            console.log('Pending transaction hash', sender);
            const sendHash = await web3.eth.sendTransaction(sender);
            console.log('successfuly send response sendHash is:', sendHash);
            
        } catch (error: any) {
            setErrorSend(error.message);
            console.log('error  during send transaction', error);
        }
    }

    const [errrorBuying, setErrorBuying] = useState<any>();
    const [formBuying, setFormBuying] = useState<BuyToken>({
        _to: '',
        _amount: 0
    })
    const initBuying: BuyToken = {
        _to: '',
        _amount: 0
    }
    const preparedSubmitBuying = ( data: BuyToken): PreparedBuying => {
  
        return {
            address: data._to,
            amount: data._amount
        }
    }

    const handleInputChangeBuying = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormBuying({ ...formBuying, [name]: value });
    }

    const handleSubmitBuying = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorBuying('');

        try {
            const response = await axios.post(`${url}Contract/Fycoinx/BuyFyx`, preparedSubmitBuying(formBuying));
            if (response.data) {
                console.log(response.data);
                alert("successfully transaction buy to " + formBuying._amount + " by " + formBuying._to);
                setFormBuying(initBuying);
            }

        } catch (error: any) {
            setErrorBuying(error)
            console.log(error);
            alert("an error has occurred during the purchase of token it is this error: " + errrorBuying.messge);
        }
    }


    return (
        <>
            <h1 className="text-xl font-bold text-center mt-20 leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white">
                Welcome to the transaction form page
            </h1>

            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center  text-2xl font-semibold mt-6"> Sender token</h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSendToken}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="_from" value={formSend._from} onChange={handleInputeChangeSend} id="from" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="from" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">From Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="_to" value={formSend._to} onChange={handleInputeChangeSend} id="receive" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="receive" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">To Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="_amount" value={formSend._amount} onChange={handleInputeChangeSend} id="amount" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="amount" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount:</label>
                        </div>

                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400' >
                            <button type="submit" className="font-semibold  text-white"> Transfer </button>
                        </div>
                    </form>
                </div>

            </div>
          
            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-16 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Purchase of token</h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitBuying}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="_to" value={formBuying._to} onChange={handleInputChangeBuying} id="recep" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="recep" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Receive Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group ">
                            <input type="number" name="_amount" value={formBuying._amount} onChange={handleInputChangeBuying} id="amount" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="amount" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Quantity of token :</label>
                        </div>
                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Buying </button>

                        </div>
                    </form>
                </div>

            </div>

        </>
    );
};

export default FycoinxForm;