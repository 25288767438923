import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Web3, { eth } from 'web3';
import { BookInterface } from '../../Interface/Book';
import detectEthereumProvider from '@metamask/detect-provider';
import { getBlock } from 'web3/lib/commonjs/eth.exports';


interface ApprobOwner {
    recept: string;
    role: string
}

interface ApprobAuthor {
    recept: string;
    roles: string
}
interface Active {
    recept: string
}
interface Reward {
    _to: string;
}

const StateBook = () => {
    const { idBookDto } = useParams();
    const [book, setBook] = useState<BookInterface | null>(null);
    const [fyxAddress, setFyxAddress] = useState<string>();
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [abi, setAbi] = useState<any>();
    const [abiFyx, setAbiFyx] = useState<any>();
    const [account, setAccount] = useState('');
    const [balanceContrib, setBalanceContrib] = useState<number>(0);


    const url = process.env.REACT_APP_SPRING_API_URL;

    const [formApprovOwner, setFormApprovOwner] = useState<ApprobOwner>({
        recept: '',
        role: ''
    });
    const [formApprovAuthor, setFormApprovAuthor] = useState<ApprobAuthor>({
        recept: '',
        roles: ''
    });
    const [formActiveAuthor, setFormActiveAuthor] = useState<Active>({
        recept: ''
    });
    const [formReward, setFormReward] = useState<Reward>({
        _to: ''
    });

    useEffect(() => {
        const makeActionBook = async () => {
            try {
                const getBook = await axios.get(`${url}Book/${idBookDto}`);
                setBook(getBook.data);

                const fyxResponse = await axios.get(`${url}Contract/Fycoinx/Address`);
                setFyxAddress(fyxResponse.data);

                const provider: any = await detectEthereumProvider();
                if (provider) {
                    const web3Instance = new Web3(provider);
                    setWeb3(web3Instance);
                    await provider.request({ method: 'eth_requestAccounts' });

                    const accounts = await web3Instance.eth.getAccounts();
                    setAccount(accounts[0]);

                    const getAbi = await axios.get(`${url}Contract/Contribution/AbiFile`);
                    setAbi(getAbi.data);

                    const getAbiFyx = await axios.get(`${url}Contract/Fycoinx/AbiFile`);
                    setAbiFyx(getAbiFyx.data);

                    if (web3Instance) {
                        const getContractcontrib = new web3Instance.eth.Contract(getAbi.data, getBook.data.addressBookDto);
                        const balance = await getContractcontrib.methods.contributions(account).call();
                        setBalanceContrib(Number(balance));
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        makeActionBook();
    }, [book]);

    if (!book) {
        return <div> Loading ... </div>;
    }

    const handleInputApprovOwner = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormApprovOwner({ ...formApprovOwner, [name]: value });
    }

    const handleRadioOwnerApprove = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormApprovOwner({ ...formApprovOwner, [name]: value });
        console.log(formApprovOwner.role);
    }

    const handleInputApprovAuthor = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormApprovAuthor({ ...formApprovAuthor, [name]: value });
    }

    const handleRadioAuthorApprove = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormApprovAuthor({ ...formApprovAuthor, [name]: value });
        console.log(formApprovAuthor.roles)
    }

    const handleInputformReward = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormReward({ ...formReward, [name]: value });
    }

    const handleInputActiveAuthor = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormActiveAuthor({ ...formActiveAuthor, [name]: value });
    }

    const ActivateAuthorByOwner = async (event: React.FormEvent) => {
        event.preventDefault();
        if (web3 && abi) {
            try {
                const contractCont = new web3.eth.Contract(abi, book.addressBookDto);
                await contractCont.methods.activeAuth(formActiveAuthor.recept).send({ from: account });
                console.log('Transaction successful');
                alert('Transaction successful');
            } catch (error) {
                console.log(error);
            }
        }

    }

    const ApproveByOwner = async (event: React.FormEvent) => {
        event.preventDefault();
        if (web3 && abi) {
            try {
                const contractCont = new web3.eth.Contract(abi, book.addressBookDto);
                await contractCont.methods.setOwnerApprob(book.addressBookDto, formApprovOwner.recept, Number(formApprovOwner.role)).send({ from: account });
                alert('Succsessfully Approbation by Owner');
            } catch (error: any) {
                console.log(error.data);
            }
        }
    }

    const ApproveByAuthor = async (event: React.FormEvent) => {
        event.preventDefault();
        if (web3 && abi) {
            try {
                const contractCont = new web3.eth.Contract(abi, book.addressBookDto);
                await contractCont.methods.setAuthApprob(book.addressBookDto, formApprovAuthor.recept, Number(formApprovAuthor.roles)).send({ from: account });
                console.log(formApprovAuthor.recept);
                alert('successfully Approbation by Author');
            } catch (error: any) {
                console.log(error);
            }
        }

    }

    const getRewardSubmit = async (event: React.FormEvent) => {
        event.preventDefault();          
        try {
            if (web3 && abi) {
                const contractContribution = new web3.eth.Contract(abi, book.addressBookDto);

                const estimateGas = await web3.eth.estimateGas({
                    from: account,
                    to: book.addressBookDto,
                    data: contractContribution.methods.reward(formReward._to).encodeABI()
                });

                const rewardAcc = await contractContribution.methods.reward(formReward._to).send({
                    from: account,
                    gas: estimateGas as unknown as string
                })
                console.log('successfully reward ', rewardAcc)
                alert('successfully reward transaction');
            }
        } catch (error: any) {
            console.log('error  during send transaction', error.data);
        }
    }

    const getRefundSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            if (web3 && abi && book) {
                const contContb = new web3.eth.Contract(abi, book.addressBookDto);
                const estimateGas = await web3.eth.estimateGas({
                    from: account,
                    to: book.addressBookDto,
                    data: contContb.methods.refund(account).encodeABI()
                });

                const rewardAcc = await contContb.methods.refund(account).send({
                    from: account,
                    gas: estimateGas as unknown as string
                })
                console.log('successfuly refund ', rewardAcc);
                alert( 'successfuly refund ' );
                /*
                const data = contContb.methods.refund().send({ from: account });
                const senderRefund = {
                    from: account,
                    to: book.addressBookDto,
                    value: '0',
                    data: data as unknown as string
                };
                console.log('Pending transaction hash', senderRefund);
                const sendHash = await web3.eth.sendTransaction(senderRefund);
                console.log('successfuly send response sendHash is:', sendHash);

            */
            }

        } catch (error: any) {
            console.log('error  during send transaction', error.data);
        }
    }

    return (
        <>
            <div>
                <h1>Balance of {account}</h1>
                <p>{balanceContrib !== null ? `${balanceContrib} tokens` : 'Loading...'}</p>
            </div>
            <h1 className='justify-center mt-40 mb-6 text-2xl text-center font-semibold'> Project status </h1>

            <div className=' ml-5  mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-2/5  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                <button type="submit" onClick={getRefundSubmit} className="font-semibold  text-white"> Click here to refund </button>
            </div>

            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-8 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Activating the author </h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={ActivateAuthorByOwner}>
                        <div className="relative z-0 w-full mb-5 group ">
                            <input type="text" name="recept" value={formActiveAuthor.recept} onChange={handleInputActiveAuthor} id="receiver" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="receiver" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Wallet address author :</label>
                        </div>
                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Activate </button>

                        </div>
                    </form>
                </div>
            </div>


            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-8 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Approval by Owner </h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={ApproveByOwner}>
                        <div className="relative z-0 w-full mb-5 group ">
                            <input type="text" name="recept" value={formApprovOwner.recept} onChange={handleInputApprovOwner} id="receiver" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="receiver" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Wallet address receive :</label>
                        </div>
                        <h3 className='mb-3 text-lgxl'> select a role to play </h3>
                        <div className="flex ">
                            <div className=" ">
                                <div className="flex items-center mb-3 me-4">
                                    <input id="author" type="radio" name='role' value="50" checked={formApprovOwner.role === "50"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="author" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Author</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="printer" type="radio" name='role' value="16" checked={formApprovOwner.role === "16"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="printer" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Printer</label>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="reader" type="radio" name='role' value="" checked={formApprovOwner.role === "7"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="reader" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Reader</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="corrector" type="radio" name='role' value="12" checked={formApprovOwner.role === "12"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="corrector" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Corrector</label>
                                </div>
                            </div>
                            <div className=" ">
                                <div className="flex items-center mb-3 me-4">
                                    <input id="editor" type="radio" name='role' value="1" checked={formApprovOwner.role === "1"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="editor" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Editor</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="designer" type="radio" name='role' value="3" checked={formApprovOwner.role === "3"} onChange={handleRadioOwnerApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="designer" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Designer</label>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Approve </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-8 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Approval by Author </h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={ApproveByAuthor}>
                        <div className="relative z-0 w-full mb-5 group ">
                            <input type="text" name="recept" value={formApprovAuthor.recept} onChange={handleInputApprovAuthor} id="receiver" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="receiver" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Wallet address receive :</label>
                        </div>

                        <h3 className='mb-3 text-lgxl'> select a role to play </h3>
                        <div className="flex ">
                            <div className=" ">
                                <div className="flex items-center mb-3 me-4">
                                    <input id="authors" type="radio" name='roles' value="50" checked={formApprovAuthor.roles === "50"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="authors" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Author</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="printers" type="radio" name='roles' value="16" checked={formApprovAuthor.roles === "16"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="printers" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Printer</label>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="readers" type="radio" name='roles' value="7" checked={formApprovAuthor.roles === "7"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="readers" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Reader</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="correctors" type="radio" name='roles' value="12" checked={formApprovAuthor.roles === "12"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="correctors" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Corrector</label>
                                </div>
                            </div>
                            <div className=" ">
                                <div className="flex items-center mb-3 me-4">
                                    <input id="editors" type="radio" name='roles' value="1" checked={formApprovAuthor.roles === "1"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="editors" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Editor</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="designers" type="radio" name='roles' value="3" checked={formApprovAuthor.roles === "3"} onChange={handleRadioAuthorApprove} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="designers" className="ms-2 text-sm font-medium text-black dark:text-gray-300">Designer</label>
                                </div>
                            </div>
                        </div>

                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Approve </button>

                        </div>
                    </form>
                </div>
            </div>



            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300 mb-8  mt-8 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Recover your reward using this form </h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={getRewardSubmit}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="_to" value={formReward._to} onChange={handleInputformReward} id="address" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="address" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Insert your wallet address :</label>
                        </div>
                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Recover </button>

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default StateBook;

