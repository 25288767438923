import React, { ChangeEvent, useState } from 'react';
import { NftDeployObj } from '../../Interface/NftDeploy';
import { BookNft } from '../../Interface/NftBook';
import { deploy, getOwnerToken, mintNft, ownerTransfer } from '../../Services/NftServices';
import { addBookNft, getNftBookByAddress, getNftOwnerToken, updateBookNft } from '../../Services/bookNftService';


interface Data {
    idBookDto: string;
    ownerAddress: string;
    authorAddress: string;
    nameAuthor: string;
    maxSupply: number;
    nftName: string;
    symbol: string;
}

interface Minted {
    owner: string;
    contractAddress: string;
    listBook: string[];
}
interface Sender {
    from: string;
    to: string;
    tokenId: number;
    contractAddress: string
}



const DeployNft: React.FC = () => {

    const [formData, setFormData] = useState<Data>({
        idBookDto: '',
        ownerAddress: '',
        authorAddress: '',
        nameAuthor: '',
        maxSupply: 0,
        nftName: '',
        symbol: ''
    });

    const [formDeploy, setFormDeploy] = useState<NftDeployObj>({
        ownerAddress: '',
        authorAddress: '',
        nameAuthor: '',
        maxSupply: 0,
        nftName: '',
        symbol: ''

    });
    const [nftBook, setNftBook] = useState<BookNft>({
        idNftBookDto: '',
        contractAddressNftDto: '',
        ownerAddressTokenDto: '',
        symbolDto: formData.symbol,
        maxSupplyDto: formData.maxSupply,
        tokenIdDto: 0,
        idBookDto: formData.idBookDto
    });

    const [formMint, setFormMint] = useState<Minted>({
        owner: '',
        contractAddress: '',
        listBook: []
    });

    const [formSend, setFormSend] = useState<Sender>({
        from: '',
        to: '',
        tokenId: 0,
        contractAddress: ''
    })

    const [contractAddress, setContractAddress] = useState<string>();

    const preparDeploy = (data: Data): NftDeployObj => {
        const deploy: NftDeployObj = {
            ownerAddress: data.ownerAddress,
            authorAddress: data.authorAddress,
            nameAuthor: data.nameAuthor,
            maxSupply: data.maxSupply,
            nftName: data.nftName,
            symbol: data.symbol
        }
        return deploy;
    }

    const preparNftBook = (data: Data): BookNft => {
        const book: BookNft = {
            idNftBookDto: '',
            contractAddressNftDto: '',
            ownerAddressTokenDto: '',
            symbolDto: data.symbol,
            maxSupplyDto: data.maxSupply,
            tokenIdDto: 0,
            idBookDto: data.idBookDto
        } 
        return book;
    }

    const handleInputDeploy = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmitDeploy = async (event: React.FormEvent) => {
        event.preventDefault();
        try {           
            const deployBook = preparDeploy(formData);          
            const response: any = await deploy(deployBook);           
            setContractAddress(response.data);
            const book = preparNftBook(formData)
            const update = { ...book, contractAddressNftDto: response.data };           
            const postNftBook = await addBookNft(update.idBookDto, update);
            setNftBook(postNftBook);
            console.log(postNftBook);
            alert("successfully nft deploy the contract address is" + response.data);
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleInputMint = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormMint({ ...formMint, [name]: value })
    }

    const handleSubmitMinted = async (event: React.FormEvent) => {
        event.preventDefault();
        const minted = await mintNft(formMint.listBook, formMint.owner, formMint.contractAddress);
       
    }

    const handleInputSend = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormSend({ ...formSend, [name]: value });
    }

    const handleSubmitSend = async (event: React.FormEvent) => {
        event.preventDefault();
        const response = await ownerTransfer(formSend.from, formSend.to, formSend.tokenId, formSend.contractAddress);
        console.log(response);
         const getBooks = await getNftBookByAddress(formSend.contractAddress);
         const getBook = getBooks[0];
       // const getBook = await getNftOwnerToken(formSend.to);
        console.log(getBook);
        if (getBook ) {
            const book: BookNft = { ...nftBook, idNftBookDto: getBook.idBookDto, tokenIdDto: response, contractAddressNftDto: getBook.contractAddressNftDto, ownerAddressTokenDto: formSend.to ,symbolDto: getBook.symbolDto, maxSupplyDto : getBook.maxSupplyDto };
            console.log(book);
            const postBookNft = await addBookNft(book.idNftBookDto, book);
            console.log(postBookNft);
        }
    }

    return (
        <>
            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/2 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center  text-3xl font-semibold mt-6"> Deploy NFT</h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitDeploy}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="idBookDto" value={formData.idBookDto} onChange={handleInputDeploy} id="idBook" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="idBook" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> id Book:</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="ownerAddress" value={formData.ownerAddress} onChange={handleInputDeploy} id="owner" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="owner" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Owner Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="authorAddress" value={formData.authorAddress} onChange={handleInputDeploy} id="authorA" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="authorA" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Author Address:</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="nameAuthor" value={formData.nameAuthor} onChange={handleInputDeploy} id="authorN" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="authorN" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Author Name</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="maxSupply" value={formData.maxSupply} onChange={handleInputDeploy} id="max" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="max" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Max Supply</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="nftName" value={formData.nftName} onChange={handleInputDeploy} id="nft" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="nft" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Nft Name</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="symbol" value={formData.symbol} onChange={handleInputDeploy} id="symbole" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="symbole" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">NFT Symbol</label>
                        </div>

                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400' >
                            <button type="submit" className="font-semibold  text-white"> Deploy </button>
                        </div>
                    </form>
                </div>
            </div>


            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/2 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center  text-3xl font-semibold mt-6"> Mint Nft</h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitMinted}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="owner" value={formMint.owner} onChange={handleInputMint} id="addressOw" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="addressOw" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Owner Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="contractAddress" value={formMint.contractAddress} onChange={handleInputMint} id="contract" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="contract" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Contract Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="listBook" value={formMint.listBook.join(',')} onChange={(e) => setFormMint({ ...formMint, listBook: e.target.value.split(',') })} id="list" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="list" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Enter a books paths :</label>
                        </div>
                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400' >
                            <button type="submit" className="font-semibold  text-white"> Minted </button>
                        </div>
                    </form>
                </div>
            </div>


            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-6 w-1/2 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h3 className="text-center  text-3xl font-semibold mt-6"> Send Nft By Owner</h3>

                <div className="justify-center mt-2">
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitSend}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="from" value={formSend.from} onChange={handleInputSend} id="addressfrom" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="addressfrom" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> From Adrresss :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="to" value={formSend.to} onChange={handleInputSend} id="addressTo" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="addressTo" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> To Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="number" name="tokenId" value={formSend.tokenId} onChange={handleInputSend} id="token" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="token" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Id token :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="contractAddress" value={formSend.contractAddress} onChange={handleInputSend} id="contAd" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="contAd" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Contract Address:</label>
                        </div>
                        <div className='w-full  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400' >
                            <button type="submit" className="font-semibold  text-white"> Send </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DeployNft;