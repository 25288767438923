import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Book {
    idBookDto: string;
    addressBookDto: string;
    titleDto: string;
    authorEmailDto: string;
    descriptionDto: string;
    bookGenreDto: string;
    icoStartDto: string;
    icoEndDto: string;
    priceDto: number;
    budgetDto: number;
    nftNumberDto: number;
    pageNumberDto: number;
    pictureNumberDto: number;
    manuscriptDto: string;
    promotionalMessageDto: string;
    languagesDto: string[];
    idAuthorDto: string;
    dateBook: string;
}

interface PreparBook {
    [key: string]: any
    authorEmailDto: string;
    titleDto: string;
    descriptionDto: string;
    nftNumberDto: number;
    pageNumberDto: number;
    pictureNumberDto: number;
    priceDto: number;
    budgetDto: number;
    addressBookDto: string;
    icoStartDto: string;
    icoEndDto: string;
    bookGenreDto: string;
    manuscriptDto: string;
    languagesDto: string[];
    promotionalMessageDto: string;
    idAuthorDto: string;
}
interface Person {
    idPersonDto: string;
    firstNamePersonDto: string;
    lastNamePersonDto: string;
    nationalityPersonDto: string;
    phonePersonDto: string;
    profile: string[];
    dateDto: Date;
}

interface FormProjectProps {
    bookProjectCreated: () => void ;
}

const FormProject: React.FC<FormProjectProps> = ({ bookProjectCreated }) => {
    const [formBook, setFormBook] = useState<Book>({
        idBookDto: '',
        authorEmailDto: '',
        titleDto: '',
        descriptionDto: '',
        nftNumberDto: 0,
        pageNumberDto: 0,
        pictureNumberDto: 0,
        priceDto: 0,
        budgetDto: 0,
        addressBookDto: '',
        icoStartDto: '',
        icoEndDto: '',
        bookGenreDto: '',
        dateBook: '',
        manuscriptDto: '',
        languagesDto: [],
        promotionalMessageDto: '',
        idAuthorDto: ''

    });
    const url = process.env.REACT_APP_SPRING_API_URL;    
    const messageErrorDeployContrib = "une erreur a ete produite aucours du deploiement du contract";

    const [fycoinxAddress, setFycoinxAddress] = useState<string | null>(null);
    const [contribContractAddress, setContribContractAddess] = useState<string | null>(null);
    const [errorDeploy, setErrorDeploy] = useState<any>();
    const [errorUpdate, setErrorUpdate] = useState<any>();
    const [error, setError] = useState<any>();

    const handleInputChangeBook = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormBook(prevState => ({
            ...prevState,
            [name]: name === 'nftNumberDto' || name === 'pageNumberDto' || name === 'pictureNumberDto' || name === 'priceDto' || name === 'budgetDto' ? parseInt(value) : value
        }));
    };

    const handleTextareaChangeBook = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormBook({ ...formBook, [name]: value });
    };

    const diffrenceTimes = (start: string, end: string): number => {
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();
        const difference = (endTime - startTime) / 1000;
        return difference;
    };

    useEffect(() => {
        if (formBook.idBookDto) {
            const getBook = async () => {
                try {
                    const response = await axios.get(`${url}Book/${formBook.idBookDto}`);
                    setFormBook(response.data);
                  //  console.log('use effet',response.data);                                      
                } catch (error: any) {
                    console.error('Error fetching book:', error.message);
                }
            };
            getBook();
        }
    }, [formBook.idBookDto, contribContractAddress]);

    const preparSubmit = (data: Book): PreparBook => {
        return {
            authorEmailDto: data.authorEmailDto,
            titleDto: data.titleDto,
            descriptionDto: data.descriptionDto,
            nftNumberDto: data.nftNumberDto,
            pageNumberDto: data.pageNumberDto,
            pictureNumberDto: data.pictureNumberDto,
            priceDto: data.priceDto,
            budgetDto: data.budgetDto,
            addressBookDto: data.addressBookDto,
            icoStartDto: data.icoStartDto,
            icoEndDto: data.icoEndDto,
            bookGenreDto: data.bookGenreDto,
            manuscriptDto: data.manuscriptDto,
            languagesDto: data.languagesDto,
            promotionalMessageDto: data.promotionalMessageDto,
            idAuthorDto: data.idAuthorDto
        };
    };

    const navigate = useNavigate();

    const handleSubmitBook = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const addressFyxResponse = await axios.get(`${url}Contract/Fycoinx/Address`);
            const fycoinxAddressData = addressFyxResponse.data;
            setFycoinxAddress(fycoinxAddressData);           

            const getAuthorResponse = await axios.get(`${url}GetPerson/ByEmail/${formBook.authorEmailDto}`);
            const authorData: Person = getAuthorResponse.data;
            const idAuth = authorData.idPersonDto;
            const newBook: Book = { ...formBook, idAuthorDto: authorData.idPersonDto };
                      
            if (idAuth && fycoinxAddressData) {                           
                    const responseAddBook = await axios.post(`${url}AddBook?idAuthor=${idAuth}`, preparSubmit(newBook));
                   console.log(responseAddBook.data);
                    setFormBook(responseAddBook.data);             

                if (newBook.icoStartDto && newBook.icoEndDto) {
                    const difference = diffrenceTimes(newBook.icoStartDto, newBook.icoEndDto);
                    try {  
                        // nous allons le temps d ' ico pour le text temporaire
                        const endTimeIco : number = 600  ;                  
                        const deployResponse = await axios.post(`${url}Contract/Contribution/Deploy`, null, {
                            params: {
                                fycoinxAddress: fycoinxAddressData,
                            //    icoEndDate: difference,
                                icoEndDate: endTimeIco,
                                targetAmount: newBook.budgetDto,
                                minContribution: newBook.priceDto
                            }
                        });
                        setContribContractAddess(deployResponse.data);
                         console.log(deployResponse.data);

                        try {
                            if (deployResponse.data && deployResponse.data !== messageErrorDeployContrib) {
                                const updatedBook = { ...responseAddBook.data, addressBookDto: deployResponse.data };  
                                console.log(updatedBook);                              
                                const responseUpdate = await axios.patch(`${url}UpdateBook/${updatedBook.idBookDto}`, preparSubmit(updatedBook));
                                setFormBook(responseUpdate.data);
                                console.log('update book ', responseUpdate.data);
                                alert('Congratulation to creat your book project')
                                navigate('/Fycoinx/CurrentProject') ;   
                            }
                        } catch (error) {
                            setErrorUpdate(error);
                            console.log('error Update ',error);
                        }

                    } catch (error) {
                        setErrorDeploy(error)
                        console.error('error deploy ',errorDeploy);
                    }
                }

            }
        } catch (error: any) {
            setError(error);
            console.error('Error message:', error);
        }
    };

    return (
        <>
            
            <h1 className="text-xl font-bold text-center mt-20 leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white">
                Welcome to this page dedicated to the creation and presentation of his book to the community.
            </h1>

            <div className=" mb-15 mt-10" >
                <p className=" text-xl text-center"> By filling in the fields below, you will firstly become a major player in this project designed in the world of blockchain,
                    and secondly you will enable us to have some information about you so that you can take part in the various projects
                    and activities that will be presented to the community.
                </p>
            </div>
            <div className="flex flex-col mt-10 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0">
                <div className="w-full max-w-7xl mx-auto  rounded-2xl shadow dark:border md:mt-0 group bg-gradient-to-br from-purple-500 to-pink-500  sm:max-w- xl:p-0  ">
                    <div className="p-3 space-y-2 md:space-y-3  sm:p-8">
                        <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-white md:text-2xl dark:text-white">
                            Your creat book form
                        </h1>
                        <form className="space-y-6 md:space-y-2 " onSubmit={handleSubmitBook}>

                            <div>
                                <label htmlFor="title" className="block mb-2 text-sm font-medium text-white dark:text-white">Title :</label>
                                <input type="text" name="titleDto" value={formBook.titleDto} onChange={handleInputChangeBook} id="title" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Book title" />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-white dark:text-white">Author Email :</label>
                                <input type="email" name="authorEmailDto" value={formBook.authorEmailDto} onChange={handleInputChangeBook} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Book title" />
                            </div>
                            <div className="dark:bg-gray-800">
                                <label htmlFor="description" className=" block mb-2 text-sm font-medium text-white dark:text-white"> Short description project: </label>
                                <textarea id="description" name='descriptionDto' value={formBook.descriptionDto} onChange={handleTextareaChangeBook} className="w-full  py-2 h-40  px-4  mb-3 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="briefly describe your project here...." required ></textarea>
                            </div>

                            <div className="grid gap-6 mb-6  md:grid-cols-2">
                                <div>
                                    <label htmlFor="qteNFT" className="block mb-2 text-sm font-medium text-white dark:text-white">How many NftBooks you want to produce: </label>
                                    <input type="number" name="nftNumberDto" value={formBook.nftNumberDto} onChange={handleInputChangeBook} id="qteNFT" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="pageNber" className="block mb-2 text-sm font-medium text-white dark:text-white"> How many pages will this book contain?  </label>
                                    <input type="number" id="pageNber" name="pageNumberDto" value={formBook.pageNumberDto} onChange={handleInputChangeBook} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="pictureNbre" className="block mb-2 text-sm font-medium text-white dark:text-white"> How many pictures will this book contain?  </label>
                                    <input type="number" name="pictureNumberDto" value={formBook.pictureNumberDto} onChange={handleInputChangeBook} id="pictureNber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="language" className="block mb-2 text-sm font-medium text-white dark:text-white"> Language in use:  </label>
                                    <input type="string" name="languagesDto" value={formBook.languagesDto.join(',')} onChange={(e) => setFormBook({ ...formBook, languagesDto: e.target.value.split(',') })} id="language" placeholder='Frenche' className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="icoS" className="block mb-2 text-sm font-medium text-white dark:text-white"> Book Ico Start Date:  </label>
                                    <input type="date" name="icoStartDto" value={formBook.icoStartDto} onChange={handleInputChangeBook} id="icoS" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="icoE" className="block mb-2 text-sm font-medium text-white dark:text-white"> Book Ico End Date:   </label>
                                    <input type="date" name="icoEndDto" value={formBook.icoEndDto} onChange={handleInputChangeBook} id="icoE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="price" className="block mb-2 text-sm font-medium text-white dark:text-white"> Minimun to pay (in $) for a pre-order?</label>
                                    <input type="number" name="priceDto" value={formBook.priceDto} onChange={handleInputChangeBook} id="price" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                <div>
                                    <label htmlFor="budget" className="block mb-2 text-sm font-medium text-white dark:text-white"> Total project budget: </label>
                                    <input type="number" name="budgetDto" value={formBook.budgetDto} onChange={handleInputChangeBook} id="budget" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                            </div>

                            <div className="flex items-center me-4">
                                <span className="text-white">What literary genre will your book belong to?</span>
                            </div>
                            <div className="flex ">
                                <div className="flex items-center mb-3 me-4">
                                    <input id="narrative" type="radio" name='bookGenreDto' value="Narrative" checked={formBook.bookGenreDto === "Narrative"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="narrative" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Narrative</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="novel" type="radio" name='bookGenreDto' value="Novel" checked={formBook.bookGenreDto === "Novel"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="novel" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Novel</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="story" type="radio" name='bookGenreDto' value="Story" checked={formBook.bookGenreDto === "Story"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="story" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Story</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="science" type="radio" name='bookGenreDto' value="Scientist" checked={formBook.bookGenreDto === "Scientist"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="science" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Scientist</label>
                                </div>
                                <div className="flex items-center mb-3 me-4">
                                    <input id="poetic" type="radio" name='bookGenreDto' value="Poetic" checked={formBook.bookGenreDto === "Poetic"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="poetic" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Poetic</label>
                                </div>
                                <div className="flex items-cente mb-3  me-4">
                                    <input id="theatral" type="radio" name='bookGenreDto' value="Theatrical" checked={formBook.bookGenreDto === "Theatrical"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="theatral" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Theatrical </label>
                                </div>
                                <div className="flex items-cente mb-3 me-4">
                                    <input id="other" type="radio" name='bookGenreDto' value="Other" checked={formBook.bookGenreDto === "Other"} onChange={handleInputChangeBook} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="other" className="ms-2 text-sm font-medium text-white dark:text-gray-300">Other </label>
                                </div>
                            </div>
                            <div className="dark:bg-gray-800">
                                <label htmlFor="manuscrit" className=" block mb-2 text-sm font-medium text-white dark:text-white"> Present an extract from your manuscript (the first chapter) to the community, who will contribute via pre-orders. </label>
                                <textarea id="manuscrit" name='manuscriptDto' value={formBook.manuscriptDto} onChange={handleTextareaChangeBook} className="w-full  py-2 h-80  px-4  mb-4 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Insert your manuscript in this area..." required ></textarea>
                            </div>
                            <div className="dark:bg-gray-800">
                                <label htmlFor="promotion" className=" block mb-2 text-sm font-medium text-white dark:text-white"> Promotional Message: </label>
                                <textarea id="promotion" name='promotionalMessageDto' value={formBook.promotionalMessageDto} onChange={handleTextareaChangeBook} className="w-full  py-2 h-36  px-4  mb-3 rounded-t-lg text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Send a motivational message to the community to encourage people to contribute to the project....." required ></textarea>
                            </div>
                            <div className="flex justify-around mt-6">
                                <button type="submit" className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-bleu-700 dark:focus:ring-blue-800">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FormProject;


