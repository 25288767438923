import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookInterface } from '../../Interface/Book';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';


interface BookContrib {
    _from: string;
    _tokenAmount: number
}

const ContributionBook: React.FC = () => {

    const { idBookDto } = useParams<{ idBookDto: string }>();
    const [contributions, setContributions] = useState<string[]>([]);
    const [book, setBook] = useState<BookInterface | null>(null);
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [abi, setAbi] = useState<any | null>(null);
    const [abiFyx, setAbiFyx] = useState<any | null>(null);
    const [account, setAccount] = useState<string>();
    const url = process.env.REACT_APP_SPRING_API_URL;

    const [formContrib, setFormContrib] = useState<BookContrib>({
        _from: '',
        _tokenAmount: 0,
    });

    useEffect(() => {
        const getBook = async () => {
            try {
                const response = await axios.get(`${url}Book/${idBookDto}`);
                setBook(response.data);

                const provider: any = await detectEthereumProvider();
                if (provider) {
                    const web3Instance = new Web3(provider);
                    setWeb3(web3Instance);
                    await provider.request({ method: 'eth_requestAccounts' });
                    const accounts = await web3Instance.eth.getAccounts();
                    setAccount(accounts[0]);
                    setFormContrib(prev => ({ ...prev, _from: accounts[0] }));

                    const abiContract = await axios.get(`${url}Contract/Contribution/AbiFile`);
                    setAbi(abiContract.data);

                    const abiFycoinx = await axios.get(`${url}Contract/Fycoinx/AbiFile`);
                    setAbiFyx(abiFycoinx.data);

                    await fetchContributions(web3Instance, abiContract.data, response.data.addressBookDto);
                  
                }
            } catch (error) {
                console.error('Error fetching book:', error);
            }
        };
        getBook();
    }, [idBookDto]);      

    
    if (!book) {
        return <div>Loading...</div>;
    }

    const handleInputElement = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormContrib({ ...formContrib, [name]: value })
    }

    const handleSubmitContribution = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!web3 || !abi || !abiFyx) {
            console.error('web3 and ABI and abiFyx is not initialized');
            return;
        }

        if (!account || !formContrib._from || formContrib._from.trim() === '') {
            console.error('Invalid sender address:', formContrib._from);
            return;
        }

        try {
            const fyxAddress = await axios.get(`${url}Contract/Fycoinx/Address`);
            const fyxAdressResp = fyxAddress.data;
            const contractFyx = new web3.eth.Contract(abiFyx, fyxAdressResp);
          //  console.log('Fycoinx contract address:', fyxAdressResp);

            console.log('Encoding approve transaction data...');
            const data = contractFyx.methods.approve(book.addressBookDto, web3.utils.toWei(formContrib._tokenAmount.toString(), 'ether')).encodeABI();
            console.log('address contract contrib', book.addressBookDto)
            const approve = {
                from: account,
                to: fyxAdressResp,
                value: '0',
                data: data, // as unknown as string,
                gas: 200000
            };

            console.log('Pending transaction approve hash', approve);
            const hash = await web3.eth.sendTransaction(approve);
            console.log('successfully buy Fycoinx tokens approve', hash);

            const contract = new web3.eth.Contract(abi, book.addressBookDto);
            const dataBook = contract.methods.contribute(formContrib._from, formContrib._tokenAmount, web3.utils.toWei(formContrib._tokenAmount.toString(), 'ether')).encodeABI();
            const sender = {
                from: account,
                to: book.addressBookDto,
                value: '0',
                data: dataBook as unknown as string
            };
            console.log('Pending transaction hash', sender);
            const sendHash = await web3.eth.sendTransaction(sender);
            console.log('successfuly send response sendHash is:', sendHash);

        } catch (error: any) {
            console.log('error  during send transaction', error.data);
            if (error.data) {
                console.error('Error data:', error.data);
            }
            if (error.message) {
                console.error('Error message:', error.message);
            }

        }
    }

    const fetchContributions = async (web3Instance: Web3, contractAbi: any, contractAddress: string) => {
        if (web3) {
            try {
                const contract = new web3.eth.Contract(contractAbi, contractAddress);
                const contributionList = await contract.methods.getListcontribution().call();
                if (Array.isArray(contributionList)) {
                    setContributions(contributionList)
                } else {
                    console.error('Contribution list is not an array:', contributionList);
                }

            } catch (error) {
                console.error('Error fetching contributions:', error);
            }
        }

    };

    const handleButtonList = async () => {
        if(web3)
         await fetchContributions(web3, abi, book.addressBookDto);
    }

    return (
        <>
            <h1 className='text-3xl font-semibold mt-20'>Title : {book.titleDto}</h1>
            <h1 className='justify-center mt-4 mb-2 text-2xl text-center font-semibold'> Congratulations on being part of this project through your contribution. </h1>
            
            <div className=" group bg-gradient-to-br from-blue-400 to-violet-300  mt-8 w-1/3 items-center justify-center p-6 space-y-3  space-x-2 px-6 py-1  mx-auto  lg:py-0  leading-normal bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className='justify-center mt-10 mb-6 text-lg text-center font-semibold'> Send your contribution </h1>
                <div className=' justify-center'>
                    <form className="max-w-md mb-6 mx-auto" onSubmit={handleSubmitContribution}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="_from" value={formContrib._from} onChange={handleInputElement} id="address" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="address" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Wallet Address :</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group ">
                            <input type="number" name="_tokenAmount" value={formContrib._tokenAmount} onChange={handleInputElement} id="amount" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                            <label htmlFor="amount" className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount :</label>
                        </div>
                        <div className='w-full mb-6  bg-green-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-xl sm:w-auto  py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-400'>
                            <button type="submit" className="font-semibold  text-white"> Contributed </button>

                        </div>
                    </form>
                </div>
            </div>

        <h1 className='font-semibold text-xl mt-20'> Contributions lists</h1>
            <div className="mt-4">
                <button type="button" onClick={handleButtonList} className="py-2.5 px-5 me-2 mb-2 text-sm font-semibold text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-green-200 hover:text-white-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-green-600">Click here to see</button>
                <ul>
                    {contributions.map((contribution, index) => (
                        <li key={index} className="bg-gray-100 dark:bg-gray-700 p-2 mb-2 rounded">{contribution}</li>
                    ))}
                </ul>
            </div>

            
        </>
    );
};

export default ContributionBook;