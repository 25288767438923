import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {


    return (
        <>
            <div className="items-center  flex justify-between  ">

                <div className="flex items-end justify-start rtl:justify-end">
                    <img src="/logo512.png" className="h-8 me-3" alt="Logo" />
                    <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">Fycoinx</span>
                </div>
                <div >
                    <form className="mx-auto">
                        <div className="flex w-full" >
                            <input type="text" className="w-full px-96 mr-6 p-2 ps-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50" placeholder="Search..." />
                            <button>
                                <svg className="w-8 h-9 cursor-pointer text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div className=" flex cursor-pointer ">
                    <input type="checkbox" value="" className="sr-only peer" checked />
                    <div className="relative mt-2 w-11 h-6 bg-gray-200 mr-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>

                    <form className="max-w-sm mx-auto ">
                        <select id="countries" className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option>  Connexion </option>
                            <option> Deconnexion </option>
                        </select>
                    </form>
                    <i className='ml-2 mt-2  font-bold'>/</i>
                    <div className='mt-1'>  <NavLink to={"/Fycoinx/Inscription"}> <span className="ms-3 text-xl font-medium p2 hover:bg-blue-400 focus:ring-4  text-gray-900 dark:text-gray-300">Inscription</span> </NavLink> </div>

                </div>
                <div>
                    <button type="button" className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                        <img className="w-10 h-10 rounded-full" src="/homeImg.jpg" alt="profil" />
                    </button>
                </div>


            </div>






        </>
    );
};

export default Header;