import React from 'react';

const Footer = () => {
    return (
        
        <footer className=" bg-blue-600  rounded-lg mt-10 border ">
            <div className="mx-auto w-full  max-w-screen-xl">
                <div className="grid grid-cols-2  gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                    <div>
                        <ul className="text-black ">
                            <li className="mb-4">
                                <a href="#" className=" hover:underline text-white">About</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Careers</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Brand Center</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Blog</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div>                          
                        <ul className="text-black font-medium">
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Discord Server</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Twitter</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Facebook</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div>                            
                        <ul className="text-black font-medium">
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Privacy Policy</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Licensing</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div>                           
                        <ul className="text-black font-medium">
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">iOS</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Android</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">Windows</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline text-white">MacOS</a>
                            </li>
                        </ul>
                    </div>
                </div>
            <div className="px-4 py-6 md:flex md:items-center md:justify-between">
                <span className="text-sm text-white  dark:text-gray-300 sm:text-center "> © 2023 Rhcoinx. All Rights Reserved.
                </span>
                
            </div>
        </div>
    </footer>


    );
};

export default Footer;