import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookInterface } from '../Interface/Book';

import axios from 'axios';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { PersonInterface } from '../Interface/Person';
interface CardProjProps {
    book: BookInterface;
}


const CardProj: React.FC<CardProjProps> = ({ book }) => {

    const [author, setAuthor] = useState<PersonInterface>();
    const [web3, setWeb3] = useState<any>();
    const [abi, setAbi] = useState<any>();

    const [times, setTimes] = useState<number>(0);

    const differenceTimes = (icoEnd: number): number => {
        const difference = Math.max((icoEnd) / 1000, 0);
        return difference;
    };

    const [offTime, setOffTime] = useState<number>(() => differenceTimes(times));
    const url = process.env.REACT_APP_SPRING_API_URL
    const route: string = "/Fycoinx/Book/";


    useEffect(() => {
        const getTimes = async () => {
            try {
                const provider: any = await detectEthereumProvider();
                if (provider) {
                    const instanceWeb3 = new Web3(provider);
                    setWeb3(instanceWeb3)

                    const nameRes = await axios.get(`${url}Person/${book.idAuthorDto}`);
                    setAuthor(nameRes.data);

                    const abiContract = await axios.get(`${url}Contract/Contribution/AbiFile`);
                    setAbi(abiContract.data);

                    if (instanceWeb3 && abiContract.data) {
                        const contract = new web3.eth.Contract(abiContract.data, book.addressBookDto);
                        const getTime = await contract.methods.endDate().call();
                        const startTime = await contract.methods.times().call();
                        const timeInSeconds = Number(getTime) - Number(startTime);
                        setTimes(timeInSeconds);
                        setOffTime(differenceTimes(timeInSeconds));
                        console.log(timeInSeconds);
                    }
                }
            } catch (error) {
                console.log(error);
            }

        }; getTimes();

        const interval = setInterval(() => {
            setOffTime(prevTime => Math.max(prevTime - 1, 0));
        }, 1000);
        return () => clearInterval(interval);

    }, [book.addressBookDto]);


    const formatTimes = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
    };

    const navigate = useNavigate();

    const handleReadMoreClick = () => {
        navigate(`${route}Detail/${book.idBookDto}`);
    }

    const handleContributionClick = () => {
        navigate(`${route}Contribution/${book.idBookDto}`);
    }

    return (
        <>
            <div className='flex flex-cols-2 mt-2 '>
                <div className='w-full  rounded-lg'>
                    <img className="object-cover w-full rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src="/profil.jpeg" alt="logo" />
                    <h3 className=' mt-2 text-xl  font-bold'> {author?.firstNamePersonDto} </h3>
                    {/*  <h5 className='mt-3 '> {person?.phonePersonDto}</h5> */}
                    <h5 className=' mb-4'>{book.authorEmailDto}</h5>
                    <h4 className='text-lg  mb-2'>End contribution!</h4>
                    <span className=' bg-purple-100 text-purple-800   px-0.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300 font-semibold text-xl'>  {formatTimes(offTime)}</span>
                </div>
                <div className=''>
                    <h4 className='text-2xl font-semibold mt-4 ml-3 pb-3'>{book.titleDto}</h4>                
                        <p className='font-light text-center text-xl'>
                            {book.descriptionDto}
                        </p>    

                    <div className='flex space-x-3  ml-1 mt-16' >
                        <button type="button" onClick={handleReadMoreClick} className="  py-2 px-1 text-center  mb-2   text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-lg ">
                            Read more
                        </button>
                        <button type="button" onClick={handleContributionClick} className="  py-2 px-1 text-center  mb-2   text-white bg-gradient-to-r from-violet-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-lg ">
                            contribuat
                        </button>
                        <h3 className=" py-2 px-1 text-center text-lg font-semibold  mb-2   text-black   "> Price : ${book.priceDto}</h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardProj;